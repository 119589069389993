import React, { Fragment, useState } from "react";
import propTypes from "prop-types";
import { Row, Col, Form, Image, Button } from "react-bootstrap";
import TextField from "@components/forms/TextField/TextField";
import { InputSelect } from "@components/select/Select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MainButton from "@components/buttons/Buttons";
import { Required } from "@components/utils";
import { getIndustries, handleErrorMessage, messageAlert } from "@utils/helpers/functions";
import { useMutation, useQuery } from "react-query";
import constant from "@utils/constants/constants";
import FormInput from "@components/forms/FormInput/FormInput";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { uploadCertificate } from "src/api/User/User";


const validationSchema = yup.object({});
export default function Education({
  disabled = false,
  state,
  colSize,
  onSubmit,
  onPreviousButtonClicked,
  loading,
}) {
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [controlState, setControlState] = useState(state);
  function handleChange({ target }) {
    if(target.name === 'cert_upload'){
      const fileType = target.files[0]?.name?.split('.')[1]
      setControlState({ ...controlState, file:target.files[0], [target.name]: URL.createObjectURL(target.files[0]), fileType:fileType });
    }else{
      setControlState({ ...controlState, [target.name]: target.value });
    }
  }

  const educationLevel = [
    { label: "No formal education", value: "no_formal_education" },
    { label: "JHS/JSS", value: "junior_high_school" },
    { label: "Middle", value: "junior_high_school" },
    { label: "SHS/SSS", value: "senior_high_school" },
    { label: "Vocational/Technical/Commercial", value: "vocational" },
    { label: "Teacher Training/Agric/Nursing Certificate", value: "diploma" },
    { label: "Certificate/Diploma", value: "diploma" },
    { label: "Tertiary - HND", value: "degree" },
    { label: "Tertiary - Bachelor's Degree", value: "degree" },
    { label: "Tertiary - Postgraduate", value: "degree" },
    { label: "Tertiary - Master's Degree", value: "masters" },
    { label: "Tertiary - Ph.D", value: "masters" },
    { label: "Others", value: "other" },
  ];
  
  

  const mutationUpload = useMutation(uploadCertificate);


  const { data: responseData} = useQuery("industries", getIndustries);
  const industries = responseData?.data;

  var industryState = watch('industry') ? JSON.parse(watch('industry')).name : state?.industry?.name

  function uploadCert(){
    const file_type = watch('educational_level')==='vocational'?'training_certificate':'school_certificate';
    const formData = new FormData()
    formData.append('file', controlState.file, controlState.file?.name);
    formData.append('file_type', file_type);
    formData.append('user', state?.id);
    
    mutationUpload.mutate(
      formData,
      {
        onSuccess: () => {
          messageAlert("success", 'Upload successful');
        },
        onError: (error) => {
          var errorMessage = handleErrorMessage(error?.response);
          messageAlert("error", errorMessage);
        },
      }
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex align-items-center h-100">
        <Col md={colSize ? colSize : 8}>
          <TextField
            text={"Education Information"}
            className={"fs-5 fw-bold mb-5 text-center"}
          />
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="educational_level"
                control={control}
                required
                render={({ field }) =>(
              <InputSelect
                {...field}
                defaultSelect={state?.educational_level}
                defaultValue={state?.educational_level}
                title="Educational level"
                name="educational_level"
                options={educationLevel}
                isDisabled={disabled}
                isRequired={true}
                error={errors.educational_level}
                onChange={e => {
                  field?.onChange(e);
                  handleChange(e);
                }}
              />)}/>

              {controlState?.educational_level === "other"  &&
              <div className="mt-2">
                <Controller
                name="other_educational_level"
                control={control}
                render={({ field }) =>(
                <FormInput
                  {...field}
                  name="other_educational_level"
                  isDisabled={disabled}
                  type="text"
                  placeholder="Specify"
                  defaultValue={state?.other_educational_level}
                  
                />
                )}/></div>}

              {controlState?.educational_level !== "no_formal_education" &&
                <Fragment>
                  <Controller
                    name="cert_upload"
                    control={control}
                    className="mt-3"
                    render={({ field }) =>(
                    <FormInput
                      {...field}
                      title={" "}
                      name="cert_upload"
                      type="file"
                      accept=".pdf, .jpg, .png"
                      isDisabled={disabled}
                      onChange={e => {
                        field?.onChange(e);
                        handleChange(e);
                      }}
                    /> 
                    )}/>
                    {controlState?.cert_upload && <Fragment>
                      {constant.ACCEPTED_IMAGE_UPLOAD.includes(controlState?.fileType) &&
                        <Image src={controlState?.cert_upload} thumbnail={true} />
                      }
                      {controlState?.fileType === 'pdf' &&
                        <iframe src={controlState?.cert_upload}/>
                      }
                      <Button variant="primary" size="sm" onClick={()=>{uploadCert()}} disabled={mutationUpload.isLoading}>
                        {mutationUpload.isLoading?'Uploading...':'Upload Certificate'}
                        </Button>
                    </Fragment>
                    } 
                </Fragment>
              }
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>
                If Vocational/Technical tick appropriate 
              </Form.Label>
              <Row>
                <Col>
                  {constant.VOCATIONAL_SKILLS?.map((training, index)=>(
                    <Form.Check
                      key={index}
                      {...register(training.value, {
                        onChange: (e) => {handleChange(e)},
                      })}
                      value={training.value}
                      label={training.label}
                      name={training.value}
                      defaultChecked={state?.previous_vocational_skills?.split(',')?.includes(training.value)}
                      type="checkbox"
                      id={`inline-radio-1`}
                      disabled={
                        disabled ||
                        controlState?.educational_level !== "vocational"
                      }
                    />
                  ))}
                </Col>
              </Row>
              {watch('other_vocational_skills_check') &&
              <Controller
                name="other_previous_vocational_skills"
                control={control}
                render={({ field }) =>(
                  <FormInput
                    {...field}
                    name="other_previous_vocational_skills"
                    isDisabled={disabled}
                    type="text"
                    placeholder="Specify"
                    defaultValue={state?.other_previous_vocational_skills}
                  />
                )}/>}
            </Form.Group>
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Do you own a business?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("owns_a_business", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={true}
                    required
                    inline
                    label="Yes"
                    name="owns_a_business"
                    defaultChecked={state?.owns_a_business === true }
                    type="radio"
                    id={`inline-radio-1`}
                    disabled={disabled}
                  />
                  <Form.Check
                    {...register("owns_a_business", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={false}
                    required
                    inline
                    label="No"
                    name="owns_a_business"
                    defaultChecked={state?.owns_a_business === false}
                    type="radio"
                    id={`inline-radio-2`}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>
                If Yes, which sector?
              </Form.Label>
              <Row>
                <Col>

                {industries?.map((sector, index) =>(
                  <Form.Check
                    {...register("industry", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    key={index}
                    value={JSON.stringify(sector)}
                    label={sector.name}
                    required
                    name="industry"
                    type="radio"
                    id={`inline-radio-1`}
                    defaultChecked={sector.name === state?.industry?.name}
                    disabled={
                      disabled ||
                      ["false", false, null].includes(
                        controlState?.owns_a_business
                      )
                    }
                  />
                  ))}

                  {industryState === 'Other' && 
                  <Controller
                name="other_industry"
                control={control}
                required
                render={({ field }) =>(
                <FormInput
                    {...field}
                    name="other_industry"
                    type="text"
                    isRequired
                    defaultValue={state?.other_industry}
                    isDisabled={disabled}
                    placeholder="specify"
                  />)}/>}
                </Col>
                
              </Row>
            </Form.Group>
          </Row>

            
            {onSubmit && (
              <div className="row mt-5 mb-3">
                <Col md={4} className="mb-3">
                  <MainButton
                    text={"Previous"}
                    type={"button"}
                    onClick={onPreviousButtonClicked}
                    variant="outline-secondary"
                    size="lg"
                    className="fs-6"
                  />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <MainButton
                    text={"Save and Next"}
                    type={"submit"}
                    variant="primary"
                    size="lg"
                    className="fs-6"
                    isLoading={loading}
                  />
                </Col>
              </div>
            )}
        </Col>
      </Row>
    </Form>
  );
}

Education.propTypes = {
  disabled: propTypes.bool,
  state: propTypes.object,
  colSize: propTypes.number,
  onSubmit: propTypes.func,
  onPreviousButtonClicked: propTypes.func,
  loading: propTypes.bool,
};
