import MainButton from "@components/buttons/Buttons";
import React from "react";
import { Tabs, Tab, Row, Col } from "react-bootstrap";

import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getUserProfile } from "src/api/User/User";
import BusniessAccumen from "./Forms/BusinessAccumen";
import Education from "./Forms/Education";
import FinancialLiteracy from "./Forms/FinancialLiteracy";
import OtherInformationVerification from "./Forms/OtherInformationVerification";

import PersonalInformation from "./Forms/PersonalInformation";
import Training from "./Forms/Training";

export default function ApplicationVerification() {
  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data-verifcation", getUserProfile);

  const state = responseData?.data;
  const otherIndustry = responseData?.data?.business_profile?.other_industry
  var owns_a_business = responseData?.data?.profile?.owns_a_business;
  const fullData = {...state?.profile, other_industry:otherIndustry}

  if (isLoading) return <h1>Loading...</h1>;

  const disabled = true;
  if (error)
    return (
      <div>
        <h1>there was an error</h1>
      </div>
    );

  const training_requests = {
    ...state?.training_requests[0],
    owns_a_business: owns_a_business
  };

  return (
    <>
      <Col md={8}>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="profile" title="Profile">
          <PersonalInformation disabled={disabled} state={state?.profile} />
        </Tab>
        <Tab eventKey="education" title="Education">
          <Education disabled={disabled} state={fullData} />
        </Tab>
        <Tab eventKey="trianing" title="Training">
          <Training disabled={disabled} state={training_requests} />
        </Tab>
        <Tab eventKey="business_accumen" title="Business Accumen">
          <BusniessAccumen disabled={disabled} state={training_requests} />
        </Tab>
        <Tab eventKey="financial_literacy" title="Financial Literacy">
          <FinancialLiteracy disabled={disabled} state={training_requests} />
        </Tab>
        <Tab eventKey="other_information" title="Other Information">
          <OtherInformationVerification disabled={disabled} state={state?.profile} />
        </Tab>
      </Tabs>
      
              <Row className="mt-3 mb-5">
                <Col md={4} className="mb-3">
                  <Link
                    to="/onboarding/other_information"
                    className="text-decoration-none"
                  >
                    <MainButton
                      text={"Previous"}
                      type={"button"}
                      variant="outline-secondary"
                      size="lg"
                      className="fs-6"
                    />
                  </Link>
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <Link
                    to="/onboarding/application_terms"
                    className="text-decoration-none"
                  >
                    <MainButton
                      text={"Next"}
                      type={"button"}
                      variant="primary"
                      size="lg"
                      className="fs-6"
                    />
                  </Link>
                </Col>
              </Row>
              </Col>
            
    </>
  );
}
