import React from "react";
import propTypes from "prop-types";
import { ListGroup } from "react-bootstrap";

export default function ListItem({ text }) {
  return (
    <ListGroup.Item className=" d-flex pt-4">
      <i className="fa fa-check mx-3"></i>
      <h6>{text}</h6>
    </ListGroup.Item>
  );
}

ListItem.propTypes = {
  text: propTypes.string,
};
