import React, { Fragment } from "react";
import { Col, Row} from "react-bootstrap";

import OnboardProgress from "@components/layout/navigation/OnboardProgressNav";
import { ToastContainer } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { getUserProfile, updateFullUserProfile } from "src/api/User/User";
import { useNavigate } from "react-router-dom";
import PersonalInformation from "@components/forms/Auth/Forms/PersonalInformation";
import {
  cleanArray,
  getRegionOrDistrictObject,
  handleErrorMessage,
  messageAlert,
} from "@utils/helpers/functions";
import OnboardTopNav from "@components/layout/OnboardTopNav";
import constant from "@utils/constants/constants";

const PersonalInfo = () => {
  let navigate = useNavigate();
  const mutation = useMutation(updateFullUserProfile);

  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data-verifcation", getUserProfile);

  const state = responseData?.data?.profile;
  const onboardingState = responseData?.data?.onboarding_data;

  if (isLoading) return <h1>Loading...</h1>;

  if (error) return <h1>Error...</h1>;

  const submitData = (data) => {
    data.address = {
      community: data?.community,
      digital_address: data?.digital_address,
      zip: data?.zip,
      address_line_1: data?.address_line_1,
    };
    data.region = getRegionOrDistrictObject(data.region || state?.region);
    data.district = getRegionOrDistrictObject(data.district || state?.district);

    var languages = [];
    constant.LANGUAGE_TYPES.map((language)=>(
      languages.push(data?.[language?.value])      
  ))
    data.languages_spoken = languages.length > 0 ? cleanArray(languages) : state?.languages_spoken;

    data.other_languages_spoken = data.other_languages_spoken ? data.other_languages_spoken.split(',') : state?.other_languages_spoken;

    var disabilities = [];
    constant.DISABILIIY_TYPES.map((disability)=>(
      disabilities.push(data?.[disability?.value])      
  ))

    data.disability = data.disability ? disabilities.length > 0 ? cleanArray(disabilities).toString() : "" :null;
    
    var onboarding_data = {...onboardingState, 'personal_information':true}
    mutation.mutate(
      { id: responseData?.data?.user?.id,
        onboarding_data:onboarding_data, 
        profile: data, 
      },
      {
        onSuccess: () => {
          navigate("/onboarding/education");
        },
        onError: (error) => {
          var errorMessage = handleErrorMessage(error?.response);
          messageAlert("error", errorMessage);
        },
      }
    );
  };

  return (
    <Fragment>
      <ToastContainer />
      <OnboardTopNav/>
      <Row>
        <OnboardProgress page={1} />
        <Col md={9} sm={12}>
              <PersonalInformation
                state={state}
                onSubmit={submitData}
                loading={mutation.isLoading}
              />
          
        </Col>
      </Row>
    </Fragment>
  );
};

export default PersonalInfo;
