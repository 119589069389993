import React from "react";
import propTypes from 'prop-types'
import { Link } from "react-router-dom";

export default function TextField({text, className}) {
  return <div className={className}>{text}</div>;
}

TextField.propTypes = {
  text:propTypes.node,
  className:propTypes.string
}

export function TextLink({text, url, className}) {
  return <Link to={url} className={className}>{text}</Link>;
}

TextLink.propTypes = {
  text:propTypes.string,
  url:propTypes.string,
  className:propTypes.string
}

