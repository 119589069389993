import HeroSection from "@components/layout/layouts/StaticPagesBaseLayout/HeroSection";
import React from "react";
import { Container } from "react-bootstrap";
import hero_img from "@images/bg/jas_hero_7.jpg";
import propTypes from "prop-types";

export default function About() {
  return (
    <>
      <HeroSection img={hero_img} title="About" />
      <Container className="mt-4">
        <div className="my-3">
          <h3>About</h3>
        </div>
        <p>
          The World Bank is providing financing and technical support to the
          Government of Ghana (GoG) for the implementation of the Ghana Jobs and
          Skills Project. The Project is considered as one of the main forms of
          support for the government&rsquo;s top-priority agenda of upgrading
          skills among the country&rsquo;s population, creating more and
          better-quality jobs, and improving job outcomes for youth. It connects
          success in these areas to state stability, social cohesion, and
          economic inclusion.
        </p>
        <p>
          The Project supports the government&rsquo;s prioritized skills
          development and job creation agenda, through four main components and
          a Contingent Emergency Response;
        </p>
        <ul>
          <li>
            <p>
              1:&nbsp;Provision of Apprenticeship Training for Jobs being
              implemented by the Commission for Technical and Vocational
              Education and Training (CTVET)
            </p>
          </li>
          <li>
            <p>
              2:&nbsp;Provision of Entrepreneurship and Small and Micro
              Enterprise Support for Jobs
            </p>
            <ul>
              <li>
                <p>
                  <HeaderText title="2.1" /> Provision of entrepreneurship
                  training and competitive business start-up grants to
                  individuals for jobs being implemented by the Ghana
                  Enterprises Agency (GEA)
                </p>
              </li>
              <li>
                <p>
                  <HeaderText title="2.2" />
                  Provision of competitive grants to private enterprises for
                  expanded employment being implemented by the Commission for
                  Technical and Vocational Education and Training (CTVET)
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              3:&nbsp;Operationalization of the Ghana Labor Market Information
              System, Upgradation of District Public Employment Centers and
              Services, and Independent Performance Reviews of Selected
              Government Youth Employment and Skills Development Programs being
              implemented by the Ministry of Employment and Labor Relations
              (MELR)&nbsp;
            </p>
          </li>
          <li>
            <p>
              4:&nbsp;Capacity Development, Technical Assistance, and Project
              Management Support to Coordinating, Implementing, and Partnering
              Agencies for Enhanced Skills and Jobs Impact being implemented by
              the Ministry of Finance (MoF).
            </p>
          </li>
        </ul>
        <p>
          The Ghana Enterprises Agency &ndash; an Agency under the Ministry of
          Trade and Industry (MoTI), is implementing Subcomponent 2.1 of the
          Project. The Agency will provide entrepreneurship training to 50,000
          youth who have the potential to start a business.&nbsp;
        </p>
        <p>
          Three levels of entrepreneurship training will be offered: basic,
          intermediate, and advanced. The training will be based on
          standardized, quality-assured packages in different locations
          nationwide. All training will be delivered using an experiential
          participatory approach conducive for adult learning and will also be
          district based and non-residential.
        </p>
        <p>
          All advanced training participants will receive mentoring and coaching
          support, which encompasses guidance to prepare business plans, meet
          business registration and licensing requirements, and connect with
          input and output markets. Short duration internship, mentoring and
          guidance sessions would be arranged for some selected beneficiaries.
        </p>
        <p>
          5,000 selected youth who successfully undergo training up to the
          intermediate and advance levels would be eligible for Business Startup
          Grants. These grants will be available to individuals and self-formed
          groups, with high potential to succeed in business, and operating in
          any sector aside from the extraction of natural resources.&nbsp;
        </p>
        <p>The start-up grants will be provided for the following:</p>
        <ul>
          <li>
            <p>
              Purchase of machinery and equipment, or technology (software,
              process technology, etc.)
            </p>
          </li>
          <li>
            <p>
              Start-up business investments targeted at productivity and growth
              such as marketing and sales, innovation efforts, or operational
              efficiency
            </p>
          </li>
          <li>
            <p>
              Working capital, such as purchase of raw materials and
              consumables, and payment of salaries, rents, and other accounts
              payables
            </p>
          </li>
          <li>
            <p>
              Payment for technical advisory services, training, and
              subscriptions to platforms
            </p>
          </li>
          <li>
            <p>
              Payment for regulatory compliance, e.g., RGD, FDA registration and
              license, GSA certification, etc.
            </p>
          </li>
          <li>
            <p>
              Start-ups seeking to provide essential goods that helps to
              mitigate against the impact of the COVID-19 pandemic or any other
              disease that may pose a threat to the nation
            </p>
          </li>
        </ul>
        <p>Eligibility Criteria (Who Qualifies to Apply)</p>
        <p>The following category of youth will be targeted:</p>
        <ul>
          <li>
            <p>Between the ages of 18 to 40 years</p>
          </li>
          <li>
            <p>Minimum of Basic Education Certificate Examination&nbsp;</p>
          </li>
          <li>
            <p>Willingness to start a business</p>
          </li>
          <li>
            <p>School dropouts already in a business would be considered</p>
          </li>
          <li>
            <p>
              Special attention would be given to women and Persons with
              Disabilities (PWD)
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          You will need the following documents to complete your application
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <p>Digital Address</p>
          </li>
          <li>
            <p>Valid National ID Card preferably &ldquo;Ghana Card&rdquo;</p>
          </li>
          <li>
            <p>Certificate of the most recent education attained</p>
          </li>
          <li>
            <p>Valid mobile number</p>
          </li>
        </ul>
        <p>
          <br />
        </p>
      </Container>
    </>
  );
}

function HeaderText({ title }) {
  return <span>{title} </span>;
}

HeaderText.propTypes = {
  title: propTypes.string,
};
