import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

import OnboardProgress from "@components/layout/navigation/OnboardProgressNav";
import { ToastContainer } from "react-toastify";
import { useQuery } from "react-query";
import { getUserProfile, updateFullUserProfile } from "src/api/User/User";
import { useMutation } from "react-query";
import { createTrainingData } from "src/api/Training/Training";
import { useNavigate } from "react-router-dom";
import Training from "@components/forms/Auth/Forms/Training";
import { cleanArray, getRegionOrDistrictObject, getTrainingCategories, handleErrorMessage, messageAlert } from "@utils/helpers/functions";
import constant from "@utils/constants/constants";
import OnboardTopNav from "@components/layout/OnboardTopNav";

const TrainingInfo = () => {
  let navigate = useNavigate();

  const mutation = useMutation(createTrainingData);
  const profile_mutation = useMutation(updateFullUserProfile);
  const {
    data: categories,
  } = useQuery("trianing-categories", getTrainingCategories);
  const trainingCategories = categories?.data;

  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data-verifcation", getUserProfile);

  // const {
  //   data: trainingCategoriesData,
  // } = useQuery("trianing-categories", getTrainingCategories);
  // const trainingCategories = trainingCategoriesData?.data;

  const trainingData = responseData?.data?.training_requests;
  const newTrainingData = trainingData ? trainingData[trainingData.length - 1] : {};
  const onboardingState = responseData?.data?.onboarding_data;
  const userId = responseData?.data?.user?.id;
  var owns_a_business = responseData?.data?.profile?.owns_a_business;

  const state = {
    ...newTrainingData,
    owns_a_business: owns_a_business
  };

  var onboarding_data = {...onboardingState, 'training':true}

  if (isLoading) return <h1>Loading...</h1>;

  if (error) return <h1>Error...</h1>;

  
  const submitData = (data) => {
    if (data) {

        // data.category = typeof(data?.category)==='string'?JSON.parse(data?.category):state.category

        var category_multi = [];
        trainingCategories.map((category)=>(
          category_multi.push(data?.[category.name])      
        ))
        data.category_multi = category_multi.length > 0 ? cleanArray(category_multi).toString(): '';
        
        
        if(data.region && data.district){
          data.region = getRegionOrDistrictObject(data.region)?.name 
          data.district = getRegionOrDistrictObject(data.district)?.name
          data.previous_vocational_master_location = `${data.region}-${data.district}`;
        }


        var vocational_certs = [];
        constant.VOCATIONAL_CERTS.map((cert)=>(
            vocational_certs.push(data?.[cert.value])      
        ))
        data.previous_vocational_certificate = vocational_certs.length > 0 ? cleanArray(vocational_certs).toString(): '';

        var business_support = [];
        constant.BUSINESS_SUPPORT.map((support)=>(
            business_support.push(data?.[support.value])      
        ))
        data.business_support_required = business_support.length > 0 ? cleanArray(business_support).toString(): '';

      mutation.mutate(
        { data },
        {
          onSuccess: () => {
            navigate("/onboarding/accumen");
          },
          onError: (error) => {
            var errorMessage = handleErrorMessage(error?.response);
            messageAlert("error", errorMessage);
          },
        }
      );

      profile_mutation.mutate(
        { id:userId, onboarding_data:onboarding_data }
      );
    }
  };

  const navigateToPreviousPage = () => {
    navigate("/onboarding/education");
  };

  return (
    <Fragment>
      <ToastContainer />
      <OnboardTopNav/>
      <Row>
        <OnboardProgress page={3} />
        <Col md={9} sm={12}>
            <Training
              state={state}
              onSubmit={submitData}
              onPreviousButtonClicked={navigateToPreviousPage}
              loading={mutation.isLoading}
            />
          
        </Col>
      </Row>
    </Fragment>
  );
};

export default TrainingInfo;
