import React from "react";

export default function Divider() {
  const half_w_border = {
    width: "100px",
    height: "2px",
    background: "#1576EC",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    opacity: 1,
  };
  return <hr style={half_w_border} />;
}
