const FREQUENTYLY_ASKED_QUESTIONS = [
  {
    no: 1,
    question:
      "Who can apply for the entrepreneurship training under the Ghana Jobs and Skills Project?",
    answer:
      "Any Ghanaian youth between the ages of 18 – 40 years with a minimum of   BECE   Certification   and   a   valid   Ghanaian   Identification   Card   (ID)   who wants to start a business can register for entrepreneurship training",
  },
  {
    no: 2,
    question:
      "Can I apply if I have additional skills training after completing BECE?",
    answer:
      "Yes, youth with skills training but are yet to start a business can apply under the Project",
  },
  {
    no: 3,
    question:
      "Can I apply if I have no educational certification or did not complete BECE?",
    answer:
      "Yes, youth with skills training but are yet to start a business can apply under the Project",
  },
  {
    no: 5,
    question: "How can I register for the Project?",
    answer:
      "To register, please visit https://gea.gov.gh/yeep or any of GEA's 204 Business Advisory Centers or Business Resource Centers (BRC) across the country",
  },
  {
    no: 5,
    question: "How long will the training last?",
    answer:
      "The training would be implemented at 3 levels as follows: Basic Training for 5 Days, Intermediate Training for 10 Days and Advanced Training for  15 Days",
  },
  {
    no: 6,
    question: "How will I progress from one level to another",
    answer:
      " Determined   criteria   will   indicate   your   progress   from   one   level   to   the next",
  },
  {
    no: 7,
    question: "When will I be eligible for Coaching and Mentoring Support?",
    answer:
      " Beneficiaries who reach the advanced level would be eligible for coaching and mentoring support to finetune their business ideas",
  },
  {
    no: 8,
    question:
      "Will I be eligible for grant support after the entrepreneurship training?",
    answer:
      " Beneficiaries   are   eligible   for   grant   support   after   participating to the intermediate level",
  },
  {
    no: 9,
    question: "How will I progress from one level to another",
    answer:
      " Those   who   already   have   a   technical   skill   and   those   already   running   a business   are   eligible   at   this   stage.   However,   those   with   business   ideas  would have to complete the advance level before they can be eligible for grant support",
  },
  {
    no: 11,
    question:
      "Will I be automatically given the grant support when I apply after I am eligible",
    answer:
      "No, beneficiaries would be assessed by the Grant Management Team to meet their requirements before funds are disbursed",
  },
  {
    no: 12,
    question: "What can the grant support be used for?",
    answer: `-Purchase of machinery and equipment, or technology (software, \
        process technology, etc.) 
        - Start-up business investments targeted at productivity and growth \
        such as marketing and sales, innovation efforts, or operational \
        efficiency 
        \n 
        - Working capital, such as purchase of raw materials and \
        consumables, and payment of salaries, rents, and other accounts \
        payables \n \
        - Payment for technical advisory services, training, and subscriptions \
        to platforms \n \
        -Payment for regulatory compliance, e.g., RGD, FDA registration and \
        license, GSA certification, etc. \n \
         - Start-ups seeking to provide essential goods that helps to mitigate against the impact of the COVID-19 pandemic or any other disease`,
  },
  {
    no: 13,
    question:
      "Would  applicants   be made to  repay the Grant at some future time",
    answer:
      "Grants   are   non-repayable.   Grant   beneficiaries   would   therefore   not   be required to repay their grants.",
  },
  {
    no: 13,
    question: "Will the use of the Grant be monitored?",
    answer:
      "Yes.   The   Grants   Manager   and   his   team   will   review   or   check   all expenditures   related   to   the   grants   paid   to   each   beneficiary   to   ensure conformity with the Grants Agreement",
  },
  {
    no: 14,
    question:
      "How many selected youths would benefit from the Competitive Startup Grants?",
    answer:
      "Grant will be competitively awarded, meaning it will be given to the best applicants   using   a   set   of   pre-determined   assessment   criteria   jointly established by a local team of experts and a team from the World Bank Group. Beneficiaries of this Grant Programme will therefore be limited to 5,000 youth.",
  },
  {
    no: 15,
    question:
      "What are the assurances for transparency in the assessment of Grant applicants and subsequent award?",
    answer:
      "The   GEA   is   committed   to   assessing   grant   applicants   transparently   and ensuring   that   only   the   best   applicants   receive   the   competitive   startup grants.   A   key   step   to   ensuring   transparency   is   the   limit   of   human interferences   in   both   the   grants   application   and   assessment   processes. Applications   and   assessment   would   be   processed   electronically   to enhance transparency",
  },
];

const FREQUENTYLY_ASKED_QUESTIONS_APPLICATION_PROCESS = [
  {
    no: 1,
    question:
      "Who can apply for the entrepreneurship training under the Ghana Jobs and Skills Project?",
    answer:
      "Any Ghanaian youth between the ages of 18 – 40 years with a minimum of   BECE   Certification   and   a   valid   Ghanaian   Identification   Card   (ID)   who wants to start a business can register for entrepreneurship training",
  },
  {
    no: 2,
    question:
      "Can I apply if I have no educational certification or did not complete BECE?",
    answer:
      "Yes, you can only apply if you have already started a business in Ghana but do not have any educational certification.",
  },
  {
    no: 3,
    question: "Can I apply if I have additional skills training after completing BECE?",
    answer:
      "Yes, youth with skills training but yet to start a business are encouraged to apply for the training.",
  },
  {
    no: 4,
    question: "What do I need to apply for the training? ",
    answer:
      "You will need a valid Ghanaian Identity (ID) Card, preferably the Ghana Card to apply for training under the Project.",
  },
  {
    no: 5,
    question: "Do I need to provide a proof of my educational level?",
    answer:
      "Yes, you would need to upload a picture of your certificate or results slip as evidence of your educational attainment.",
  },
  {
    no: 6,
    question: "Do I need a valid TIN to apply?",
    answer:
      "Persons with existing businesses would be required to provide a TIN during the application process.",
  },
  {
    no: 7,
    question: "Do I require a Business Plan to apply for the training?",
    answer:
      "No, you do not require a Business Plan to apply. If you have an existing one, it will be refined during the program. ",
  },
  {
    no: 8,
    question: "Will all applicants be selected for the training?",
    answer:
      "No, only shortlisted applicants would be contacted to participate in the training.",
  },
  {
    no: 9,
    question: "How can I register for the Project?",
    answer:
      "To register, please visit https://gea.gov.gh/yeep or any of GEA's 206 Business Advisory Centers or 37 Business Resource Centers (BRC) across the country.",
  },
];

const FREQUENTYLY_ASKED_QUESTIONS_ENTREPRENEURSHIP_TRAINING = [
  {
    no: 1,
    question: "Where will the trainings be held?",
    answer:
      "The trainings will be held at the district level. Successful applicants would be provided with information on the venues for the training.",
  },
  {
    no: 2,
    question: "How long will the training last?",
    answer:
      "The training will be implemented at 3 levels based on your graduation process as follows: Basic Training for 5 Days, Intermediate Training for 10 Days and Advanced Training for 15 Days.",
  },
  {
    no: 3,
    question: "Will I be given a certificate after completing each level?",
    answer: "Yes, each participant will be given a certificate of completion.",
  },
  {
    no: 4,
    question: "How will I progress from one level to another?",
    answer:
      "Progress will be based on a participant’s performance during the training.",
  },
  // {
  //   no: 5,
  //   question: "When will I be eligible for Coaching and Mentoring Support?",
  //   answer:
  //     "Progress will be based on a participant’s performance during the training.",
  // },
  {
    no: 5,
    question: "When will I be eligible for Coaching and Mentoring Support?",
    answer:
      "Beneficiaries who reach the advanced level would be eligible for coaching and mentoring support to finetune their business ideas and receive needed support and advice.",
  },
];

const FREQUENTYLY_ASKED_QUESTIONS_COMPETITIVE_STARTUP_GRANTS = [
  {
    no: 1,
    question:
      "Will I be eligible for grant support after the entrepreneurship training? ",
    answer: "Not everyone is eligible for grant support.",
  },
  {
    no: 2,
    question: "Will I be automatically given the grant support when I apply?",
    answer:
      "No, beneficiaries would be assessed by the Grants Management Team before they would be provided with grants support.",
  },
  {
    no: 3,
    question: "What can the grant support be used for?",
    answer:
      "The start-up grants will be provided for the following:\n\
        - Purchase of machinery and equipment, or technology (software, process technology, etc.)\n \
        - Start-up business investments targeted at productivity and growth such as marketing and sales, innovation efforts, or operational efficiency\n \
        - Working capital, such as purchase of raw materials and consumables, and payment of salaries, rents, and other accounts payables\n \
        - Payment for technical advisory services, training, and subscriptions to platforms\n \
        - Payment for regulatory compliance, e.g., RGD, FDA registration and license, GSA certification, etc.\n\
        - Start-ups seeking to provide essential goods that helps to mitigate against the impact of the COVID-19 pandemic or any other disease that may pose a threat to the nation",
  },
  {
    no: 4,
    question:
      "Would applicants be made to repay the Grant at some future time?",
    answer:
      "Grants are non-repayable. Grant beneficiaries would therefore not be required to repay their grants.",
  },
  {
    no: 5,
    question: "Will the use of the Grant be monitored?",
    answer:
      "Yes. The Grants Manager and his team will review or check all expenditures related to the grants paid to each beneficiary to ensure conformity with the Grants Agreement.",
  },
  {
    no: 6,
    question:
      "How many selected youth would benefit from the Competitive Startup Grants?",
    answer:
      "Grant will be competitively awarded, meaning it will be given to the best applicants using a set of pre-determined assessment criteria jointly established by a local team of experts and a team from the World Bank Group. Beneficiaries of the Competitive Startup Grants will therefore be limited to 5,000 youth.",
  },
  {
    no: 7,
    question:
      "What are the assurances for transparency in the assessment of Grant applicants and subsequent award?",
    answer:
      "The GEA is committed to assessing grant applicants transparently and ensuring that only the best applicants receive the competitive startup grants. \
        A key step to ensuring transparency is the limit of human interferences in both the grants application and assessment processes.",
  },
];

export default {
  FREQUENTYLY_ASKED_QUESTIONS,
  FREQUENTYLY_ASKED_QUESTIONS_COMPETITIVE_STARTUP_GRANTS,
  FREQUENTYLY_ASKED_QUESTIONS_ENTREPRENEURSHIP_TRAINING,
  FREQUENTYLY_ASKED_QUESTIONS_APPLICATION_PROCESS,
};
