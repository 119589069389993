import React, {useState, useEffect} from "react";
import { Row, Col, Form } from "react-bootstrap";

import { TextField } from "../../";
import FormInput from "../../FormInput/FormInput";
import InputSelectCountries, { InputSelect } from "@components/select/Select";
import constant from "src/utils/constants/constants";
import propTypes from "prop-types";
import {
  getDistricts,
  getRegions,
  // maxDate,
} from "@utils/helpers/functions";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorText, Required } from "@components/utils";
import MainButton from "@components/buttons/Buttons";


const validationSchema = yup.object({
  has_children_below_age_12: yup.string().required("Required"),
  is_bread_winner: yup.string().required("Required"),
  disability: yup.string().required("disability"),
});
export default function PersonalInformation({
  disabled = false,
  state,
  colSize,
  onSubmit,
  loading
}) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  
  const [controlState, setControlState] = useState(state);

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(async () => {
    let regions = await getRegions();
    setRegions(regions?.data);

    handleGetDistricts(state?.region?.id)
  }, [state?.region]);

  function handleChange({ target }) {
    
    setControlState({ ...controlState, [target.name]:target.value });
  }


  
  

  async function handleGetDistricts(region) {
    let districts = await getDistricts(region)
    setDistricts(districts?.data)
}


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
    <Row className="d-flex  align-items-center h-100">
      <Col md={colSize?colSize:8}>
        <TextField
          text={"Personal Information"}
          className={"fs-5 fw-bold mb-5 text-center"}
        />
        
        <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
            <Controller
                name="country"
                control={control}
                render={({ field }) =>(
              <InputSelectCountries
                {...field}
                options={constant.COUNTRIES}
                title="Nationality"
                name="country"
                isRequired={true}
                isDisabled={disabled}
                defaultSelect={state?.country}
                defaultValue={state?.country}
                error={errors?.country?.message}
              />
                )}/>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>
              Are you the sole bread winner?
              <b className="text-danger fs-6 fw-bold">*</b>
            </Form.Label>
            <Row>
              <Col>
                <Form.Check
                  {...register("is_bread_winner")}
                  value={true}
                  required
                  inline
                  label="Yes"
                  name="is_bread_winner"
                  disabled={disabled}
                  type="radio"
                  id={`inline-radio-1`}
                  defaultChecked={state?.is_bread_winner === true}
                />
                <Form.Check
                  {...register("is_bread_winner")}
                  value={false}
                  required
                  inline
                  label="No"
                  name="is_bread_winner"
                  disabled={disabled}
                  type="radio"
                  id={`inline-radio-2`}
                  defaultChecked={state?.is_bread_winner === false}
                />
                {errors?.is_bread_winner?.message && (
                    <ErrorText error={errors?.is_bread_winner?.message}/>
                )}
              </Col>
            </Row>
          </Form.Group>
        </Row>
        <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
            <Controller
                name="id_type"
                control={control}
                render={({ field }) =>(
            <InputSelect
              {...field}
              isRequired={true}
              defaultSelect={state?.id_type}
              defaultValue={state?.id_type}
              isDisabled={disabled}
              title="Type of ID card"
              name="id_type"
              options={constant.ID_TYPES}
            />
            )}/>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Controller
                name="id_number"
                control={control}
                render={({ field }) =>(
            <FormInput
              {...field}
              isRequired={true}
              defaultValue={state?.id_number}
              title="ID Number"
              name="id_number"
              type="text"
              isDisabled={disabled}
            />
            )}/>
          </Form.Group>
        </Row>
        <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
            <Controller
            {...register("region", {
              onChange: (e) => {
              var region = e?.target?.value && JSON.parse(e?.target?.value)
               handleGetDistricts(region?.id)}
            })}

                name="region"
                control={control}
                render={({ field }) =>(
            <InputSelect
              {...field}
              defaultSelect={state?.region?.name}
              defaultValue={JSON.stringify(state?.region)}
              isDisabled={disabled}
              title="Region"
              name="region"
              isRequired={true}
              options={regions}
            />
           )}/>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Controller
                name="district"
                control={control}
                render={({ field }) =>(
            <InputSelect
              {...field}
              defaultSelect={state?.district?.name}
              defaultValue={JSON.stringify(state?.district)}
              title="District"
              isDisabled={disabled}
              name="district"
              isRequired={true}
              options={districts}
            />
          )}/>
          </Form.Group>
        </Row>
        <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
            <Controller
                name="community"
                control={control}
                render={({ field }) =>(
            <FormInput
              {...field}
              title="Community"
              name="community"
              defaultValue={state?.address?.community}
              isDisabled={disabled}
              type="text"
              placeholder="Comunity"
              isRequired={true}
            />
          )}/>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Controller
                name="zip"
                control={control}
                render={({ field }) =>(
            <FormInput
              {...field}
              title="Postal Address"
              name="zip"
              type="text"
              isDisabled={disabled}
              placeholder="Postal Address"
              isRequired={true}
              defaultValue={state?.address?.zip}
            />
          )}/>
          </Form.Group>
        </Row>
        <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
          <Controller
                name="address_line_1"
                control={control}
                render={({ field }) =>(
            <FormInput
              {...field}
              title="Residential Address"
              name="address_line_1"
              type="text"
              isDisabled={disabled}
              placeholder="Residential Address"
              defaultValue={state?.address?.address_line_1}
              isRequired={true}
            />
          )}/>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Controller
                name="digital_address"
                control={control}
                render={({ field }) =>(
            <FormInput
              {...field}
              title="Digital Address"
              name="digital_address"
              type="text"
              isDisabled={disabled}
              placeholder="Digital Address"
              isRequired={true}
              defaultValue={state?.digital_address}
            />
          )}/>
          </Form.Group>
        </Row>
        <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
            <Controller
                name="marital_status"
                control={control}
                required
                render={({ field }) =>(
              <InputSelect
                {...field}
                defaultSelect={state?.marital_status}
                defaultValue={state?.marital_status}
                title="Marital Status"
                name="marital_status"
                isDisabled={disabled}
                options={constant.MARITAL_STATUS}
                readOnly
                isRequired={true}
                error={errors?.marital_status?.message}
              />)}
            />
            {watch('marital_status') === 'other' &&
              <Controller
                name="other_marital_status"
                control={control}
                render={({ field }) =>(
                <FormInput
                  {...field}
                  title={" "}
                  name="other_marital_status"
                  isDisabled={disabled}
                  type="text"
                  placeholder="Other marital status"
                  defaultValue={state?.other_marital_status}
                />
              )}/>
            }

          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>
              Do you have children below 12 years?
              <Required/>
            </Form.Label>
            <Row>
              <Col>
                <Form.Check
                  {...register("has_children_below_age_12")}
                  defaultChecked={state?.has_children_below_age_12 === true}
                  value={true}
                  required
                  inline
                  label="Yes"
                  disabled={disabled}
                  name="has_children_below_age_12"
                  type="radio"
                  id={`inline-radio-1`}
                />
                <Form.Check
                  {...register("has_children_below_age_12")}
                  defaultChecked={state?.has_children_below_age_12 === false}
                  value={false}
                  required
                  inline
                  label="No"
                  disabled={disabled}
                  name="has_children_below_age_12"
                  type="radio"
                  id={`inline-radio-2`}
                />
                {errors?.has_children_below_age_12?.message && (
                    <ErrorText error={errors?.has_children_below_age_12?.message}/>
                )}
              </Col>
            </Row>
          </Form.Group>
          {/* <Form.Group as={Col} md="6">
            <Controller
                name="date_of_birth"
                control={control}
                render={({ field }) =>(
                <FormInput
                  {...field}
                  title="Date of birth"
                  name="date_of_birth"
                  type="date"
                  defaultValue={state.date_of_birth}
                  placeholder="Date of Birth"
                  isRequired={true}
                  maxDate={maxDate(18)}
                  error={errors?.date_of_birth?.message}
                />
              )}/>
          </Form.Group> */}
        </Row>
        <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>
              Person With Disability (PWDs)?
              <Required/>
            </Form.Label>
            <Row>
              <Col>
                <Form.Check
                  {...register("disability")}
                  value={true}
                  required
                  inline
                  label="Yes"
                  name="disability"
                  disabled={disabled}
                  type="radio"
                  id={`inline-radio-1`}
                  defaultChecked={state?.disability !== null}
                  onChange={handleChange}
                />
                <Form.Check
                  {...register("disability")}
                  value={false}
                  required
                  inline
                  label="No"
                  name="disability"
                  disabled={disabled}
                  type="radio"
                  id={`inline-radio-2`}
                  defaultChecked={state?.disability === null}
                  onChange={handleChange}
                />
                {errors?.is_bread_winner?.message && (
                    <ErrorText error={errors?.disability?.message}/>
                )}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>
              Person with disability (check appropriately)
            </Form.Label>
            <Row>
              <Col>
              {constant.DISABILIIY_TYPES.map((disability, idx) => 
                <Form.Check key={idx}
                  {...register(disability?.value)}
                  value={disability?.value}
                  label={disability?.label}
                  name={disability?.value}
                  type="checkbox"
                  id={`inline-radio-1`}
                  disabled={disabled || ['false', ''].includes(controlState?.disability)}
                  defaultChecked={state?.disability?.split(',')?.includes(disability?.value)}
                />
                )}

                {watch('other_disability_check') === 'other_disability_check'  &&
                <Controller
                name="other_disability"
                control={control}
                render={({ field }) =>(
                <FormInput
                  {...field}
                  name="other_disability"
                  isDisabled={disabled}
                  type="text"
                  placeholder="Specify"
                  defaultValue={state?.other_disability}
                  
                />
                )}/>}
              </Col>
            </Row>
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} md="6">
            <Form.Label>
              Languages Spoken
              <Required/>
            </Form.Label>
            <Row>
              <Col>
              {constant.LANGUAGE_TYPES.map((language, idx) => 
                <Form.Check key={idx}
                  {...register(language?.value)}
                  value={language?.value}
                  label={language?.label}
                  name={language?.value}
                  type="checkbox"
                  disabled={disabled}
                  id={`inline-radio-1`}
                  defaultChecked={state?.languages_spoken?.includes(
                    language?.value
                  )}
                />)}
                
              </Col>
            </Row>
          </Form.Group>
          
          <Form.Group as={Col} md="6">
          {watch('other_language_check') === 'other_language_check'  &&
            <Controller
                name="other_languages_spoken"
                control={control}
                render={({ field }) =>(
            <FormInput
              {...field}
              title="Other languages"
              name="other_languages_spoken"
              isDisabled={disabled}
              type="text"
              placeholder="Specify"
              defaultValue={state?.other_languages_spoken?.toString()}
            />
          )}/>}
          </Form.Group>


          {onSubmit && 
            <div className="row mt-5 mb-3">
              <Col md={4} className="mb-3">
                <MainButton
                  text={"Previous"}
                  type={"button"}
                  variant="outline-secondary"
                  size="lg"
                  isDisabled={true}
                  className="fs-6"
                />
              </Col>
              <Col md={{ span: 4, offset: 4 }}>
                <MainButton
                  text={"Save and Next"}
                  type={"submit"}
                  variant="primary"
                  size="lg"
                  className="fs-6"
                  isLoading={loading}
                />
              </Col>
            </div>
            }
        </Row>
      </Col>
    </Row>
    </Form>
  );
}

PersonalInformation.propTypes = {
  disabled: propTypes.bool,
  state: propTypes.object,
  colSize: propTypes.number,
  onSubmit: propTypes.func,
  loading: propTypes.bool

};
