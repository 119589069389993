import React, { Fragment, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import OtpInput from 'react-otp-input';
import propTypes from "prop-types";

const OTPForm = ({otp, handleChange, requestCode}) =>{
    useEffect(()=>{
        start()
    },[])
    
    const [requestLinkActive, setRequestLinkActive] = useState(false)
    


    var seconds=60;
    var timer;
    function myFunction() {
        
        if(seconds < 60) {
            var countDown = document.getElementById("count-down")
            if(countDown){
                countDown.innerHTML = "0:"+seconds;
            }
        }
            if (seconds >0 ) {
                seconds--;
            } else {
                clearInterval(timer);
                setRequestLinkActive(true)
            }
    } 

    function start() {
        setRequestLinkActive(false)
        if(!timer) {
            timer = window.setInterval(function() {
            myFunction();
            }, 1000);
        }
    } 

    function handleRequestOTP(e){
        e.preventDefault();
        if(requestLinkActive){
            start()
            requestCode(e)
        }

    }
   

    return(
        <Fragment>
            <Row className="mt-4 mb-4">
                <Col md={12} className="d-flex justify-content-center align-items-center ms-4">
                    <Col md={10}>
                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            separator={''}
                            inputStyle={'otp-input text-center me-2 form-control'}
                            isInputSecure={true}
                            isInputNum={true}
                        />
                    </Col>
                </Col>
            </Row>
            {requestCode &&
                <Row className="mb-3">
                    <Col>
                        <div className="text-center">Didn&apos;t get a code? You can 
                            <Link to="#" onClick={handleRequestOTP} 
                            className={`text-decoration-none ${!requestLinkActive && 'disable-cursor'}`}> request </Link> 
                            for a new code in <b id="count-down">01:00</b>
                        </div>
                    </Col>
                </Row>
            }
        </Fragment>
    )
}

OTPForm.propTypes ={
    otp:propTypes.string,
    handleChange:propTypes.func,
    requestCode:propTypes.func
}
export default OTPForm
