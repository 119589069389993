/*eslint-disable*/
import React from 'react';
import propTypes from 'prop-types';
import { Grid,Box } from '@mui/material';
// import noscore from '../../assets/noscore.png';


// var fullData = {
//     personal_information:{
//         marital_status:data?.profile?.marital_status,
//         date_of_birth:data?.profile?.date_of_birth,
//         has_children_below_age_12:data?.profile?.has_children_below_age_12,
//         country:data?.profile?.country,
//         is_bread_winner:data?.profile?.is_bread_winner,
//         id_type:data?.profile?.id_type,
//         id_number:data?.profile?.id_number,
//         region:data?.profile?.region?.name,
//         district:data?.profile?.district?.name,
//         community:data?.profile?.address?.community,
//         postal_address:data?.profile?.address?.zip,
//         residential_address:data?.profile?.address?.address_line_1,
//         digital_address:data?.profile?.digital_address,
//         disability:data?.profile?.disability,
//         languages_spoken:data?.profile?.languages_spoken,
//     },
//     education_information:{
//         educational_level:data?.profile?.educational_level,
//         previous_vocational_skills:data?.profile?.previous_vocational_skills,
//         owns_a_business:data?.profile?.owns_a_business,
//         business_sector:data?.business_profile?.industry?.name,
//     },
//     training_information:{
//         desires_training_to_improve_business:data?.training_requests[0]?.desires_training_to_improve_business,
//         training_needed:data?.training_requests[0]?.category?.name,
//         has_previous_entrepreneurial_training:data?.training_requests[0]?.has_previous_entrepreneurial_training,
//         previous_vocational_skills:data?.training_requests[0]?.previous_vocational_skills,
//         previous_vocational_master_name:data?.training_requests[0]?.previous_vocational_master_name,
//         previous_vocational_master_location:data?.training_requests[0]?.previous_vocational_master_location,
//         has_vocational_certificate:data?.training_requests[0]?.previous_vocational_certificate ? true : false,
//         previous_vocational_certificate:data?.training_requests[0]?.previous_vocational_certificate,
//         desires_employment:data?.training_requests[0]?.desires_employment,
//         can_start_business_without_support:data?.training_requests[0]?.can_start_business_without_support,
//         business_support_required:data?.training_requests[0]?.business_support_required,
       
//     },
//     business_accumen:{
//         desires_training_to_start_business:data?.training_requests[0]?.desires_training_to_start_business,
//         can_evaluate_business_idea:data?.training_requests[0]?.can_evaluate_business_idea,
//         tried_to_start_business:data?.training_requests[0]?.tried_to_start_business,
//         tried_to_start_business_industry:data?.training_requests[0]?.tried_to_start_business_industry,
//         what_happened_to_business:data?.training_requests[0]?.what_happened_to_business,
//         operated_non_family_business:data?.training_requests[0]?.operated_other_business,
//         parents_owned_business:data?.training_requests[0]?.parents_owned_business,
//         worked_for_family_business:data?.training_requests[0]?.worked_for_family_business,
//         people_you_know_who_own_business:data?.training_requests[0]?.people_you_know_who_own_business,
//         has_previous_entrepreneurial_training:data?.training_requests[0]?.has_previous_entrepreneurial_training,
//         able_to_receive_entrepreneurial_training:data?.training_requests[0]?.able_to_receive_entrepreneurial_training,
//         has_business_plan:data?.training_requests[0]?.has_business_plan,
       
//     },
//     financial_literacy:{
//         amount_needed_to_start_business:data?.training_requests[0]?.amount_needed_to_start_business,
//         amount_you_can_contribute:data?.training_requests[0]?.amount_you_can_contribute,
//         gift_expenditure_of_GHS_10_000_00:data?.training_requests[0]?.gift_expenditure,
//         owns_bank_account:data?.training_requests[0]?.owns_bank_account,
//         understands_tax_requirements:data?.training_requests[0]?.understands_tax_requirements,
//         has_previous_financial_training:data?.training_requests[0]?.has_previous_financial_training,
//         ever_created_business_plan_budget:data?.training_requests[0]?.ever_created_business_plan_budget,
//         has_business_plan:data?.training_requests[0]?.has_business_plan,
//         desires_training_to_create_plan_budget:data?.training_requests[0]?.desires_training_to_create_plan_budget,
       
//     },
//     other_information:{
//         'How did you hear about Ghana Jobs and Skills Project':data?.profile?.recommending_media_channels,
       
//     }
// }



const ApplicationOverview = ({userData}) => {

    console.log(userData,83);
    
    const overviewKeys = 
    [
        {key:'first_name',label:'First Name'},
        {key:'last_name',label:'Last Name'},
        {key:'gender',label:'Gender'},
        {key:'email',label:'Email'},
        {key:'phone_number',label:'Phone Number'},
        {key:'date_of_birth',label:'Date of Birth'},
    ];
    const profileKeys = 
    [
        
        {key:'country',label:'Nationality'},
        {key:'digital_address',label:'Digital Address'},
        {key:'region',label:'Region'},
        {key:'district',label:'District'},
        {key:'disability',label:'Person with disability?'},
        {key:'educational_level',label:'Education Level'},
        {key:'id_number',label:'ID Number'},
        {key:'id_type',label:'ID Type'},
        {key:'marital_status',label:'Marital Status'},
        {key:'has_children_below_age_12',label:'Has children below age 12?'},
        {key:'is_bread_winner',label:'Are you the sole bread winner?'},
        // {key:'owns_bank_account',label:'Owns Bank Account'},
        {key:'owns_a_business',label:'Owns a Business'},
        {key:'languages_spoken',label:'Languages Spoken'},
        {key:'community',label:'Community Postal Address'},
        {key:'zip',label:'Postal Address'},
        {key:'address_line_1',label:'Residential Address'},

        // community:data?.profile?.address?.community,
//         postal_address:data?.profile?.address?.zip,
//         residential_address:data?.profile?.address?.address_line_1,
//         digital_address:data?.profile?.digital_address,

    ];

    const educationalKeys = [
        {key:'educational_level',label:'Education Level'},
        {key:'owns_a_business',label:'Do you own a business?'},
        {key:'name',label:'If yes, which sector?'},
        {key:'previous_vocational_skills',label:'Vocational training skill, if vocational was selected'},
    ];

    const businessAcumenKeys = [
        {key:'can_evaluate_business_idea',label:' Do you know how to evaluate your business idea?'},
        {key:'operated_other_business',label:'Have you worked or operated in a non-family business before?'},
        {key:'parents_owned_business',label:'Did your close relative own a business?'},
        {key:'worked_for_family_business',label:'If yes, did you work in the family business?'},
        {key:'people_you_know_who_own_business',label:'How many people do you know well personally who own their business?'},
        {key:'has_previous_entrepreneurial_training',label:'Have you had training in entrepreneurship?'},
        {key:'able_to_receive_entrepreneurial_training',label:'Are you willing and able to dedicate time to go through the entrepreneurship training programme?'},
        {key:'has_business_plan',label:'Do you have a business plan?'},
    ]

    const financialLiteracyKeys = [
        {key:'gift_expenditure',label:'Should you receive money as a gift, to spend freely, state 3 top uses for the fund'},
        {key:'owns_bank_account',label:'Do you have a bank account'},
        {key:'understands_tax_requirements',label:'Do you understand your requirements for tax?'},
        {key:'has_previous_financial_training',label:'Have you ever had training in Financial Literacy?'},
        {key:'has_business_plan',label:'Do you have a business plan?'},  
        {key:'ever_created_business_plan_budget',label:'Have you ever created the budget for a business plan?'},
        {key:'desires_training_to_create_plan_budget',label:'If no, do you require training to do so?'},
        // {key:'amount_needed_to_start_business',label:'Amount Needed to start business'},

    ]

    const trainingRequestKeys = 
    [
        {key:'desires_training_to_improve_business',label:'Do you need any training to improve your business?'},
        {key:'name',label:'If yes, tick any applicable'},
        {key:'has_previous_vocational_training',label:'Have you had apprenticeship or skills training?'},
        {key:'category_multi',label:'If yes, what skill did you obtain after the training?'},
        {key:'has_vocational_certification',label:'Do you have any certification?'},
        {key:'previous_vocational_certificate',label:'If yes, please specify'}, 
        // {key:'desires_entrepreneurship',label:'Desires Entrepreneurship'},
        // {key:'desires_financial_traning',label:'Desires Financial Training'},
        {key:'has_business_plan',label:'Do you have a business plan?'},   
        {key:'previous_vocational_master_location',label:'Previous vocational master location'},
        {key:'previous_vocational_master_name',label:'Name of Institution/Master Craft Person which provided the training'},
       
    ];

    const otherInformationKeys = [
        {key:'recommending_media_channels',label:'How did you hear about Ghana Jobs and Skills Project'}
    ]
    const businessProfileKeys = [''];
    const [trainingRequest,setTrainingRequest] = React.useState(null);
    const [profile,setProfile] = React.useState(null);
    const [businessProfile,setBusinessProfile] = React.useState(null);
    const [overview,setOverview] = React.useState(null);
 


    React.useEffect(()=>{
    if (userData!==undefined && userData!==null)
    {
       let applicantData = userData;
       console.log(applicantData,'181');
       let _profile = applicantData?.profile?applicantData?.profile:{};
       let _overview = applicantData?.user?applicantData?.user:{};
       let _trainingRequest = applicantData?.training_requests?.[0]?applicantData?.training_requests?.[0]:{};
       let _businessProfile = applicantData?.business_profile?applicantData?.business_profile:{};
       setTrainingRequest(_trainingRequest);
       setProfile(_profile);
       setBusinessProfile(_businessProfile);
       setOverview(_overview);
       
    }

    },[userData])

    

    const returnDefaultValue = (value) => 
    {
        if (value===null||value==undefined)
        {
            return 'Not Available';
        }
        else if (value==true)
        {
            return 'Yes'
        }
        else if (value == false)
        {
            return 'No'
        }
        return value;
    }

    const returnDefaultGender = (value) => {
        if (value == null || value == undefined)
        {
            return 'Not Available';
        }
        else if (value == 'M')
        {
            return 'Male';
        }
        else 
        {
            return 'Female';
        }
    }
   
  return (
    <Box sx={{padding:'1rem'}}>
     {
        ((userData===undefined)||(userData===null)) ?
        <Box
        sx={{
          padding:'1rem',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          width:'100%',
          height:'100%'
        }}>
          <Box sx={{display:'flex',flexDirection:'column',textAlign:'center'}}>
            <Box>
              <img src={noscore}/>
            </Box>
            <Box mt={'1rem'} sx={{fontSize:'1.2rem'}}>
              Applicant has no details available
            </Box>
          </Box>
        </Box> : 
        <Box sx={{width:'100%',display:'flex',flexDirection:'column'}}>
            <Box my={'2rem'} sx={{fontSize:'1.0rem',color:'#1576ec',borderLeft:'3px solid',fontWeight:'600',paddingLeft:'0.5rem',marginTop:'1rem'}}>
                Personal Information
            </Box>
            <Grid container sx={{width:'100%'}} columnSpacing={'1.5rem'}>
                {overviewKeys.map((field,index)=>{

                    if (field.key == 'gender'){
                        return(
                            <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column', marginBottom:'2.5rem'}}>
                                <Box sx={{marginBottom:'1rem',height:'0.5rem'}}>
                                    {field.label}
                                </Box>
                                <Box>
                                    <Box sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultGender(overview?.[field.key])}</Box>  
                                </Box>
                            </Grid>
                        )
                    }
                    else 
                    {
                        return(
                            <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column', marginBottom:'2.5rem'}}>
                                <Box sx={{marginBottom:'1rem',height:'0.5rem'}}>
                                    {field.label}
                                </Box>
                                <Box>
                                    <Box sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(overview?.[field.key])}</Box>  
                                </Box>
                            </Grid>
                        )

                    }
                
                })}
            </Grid>
            <Grid container sx={{width:'100%',marginBottom:'2rem'}} columnSpacing={'1.5rem'} rowSpacing={'2.5rem'}>
                {profileKeys.map((field,index)=>{
                    if (field.key==='region'||field.key==='district')
                    {
                       return ( 
                       <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                {field.label}
                            </Box>
                            <Box>
                                <Box  sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(profile?.[field.key]?.name)}</Box>  
                            </Box>
                       </Grid>)
                    }

                    else if (field.key=='community'||field.key=='zip'||field.key=='address_line_1'){
                        return(
                            <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                                <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                    {field.label}
                                </Box>
                                <Box>
                                    <Box  sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(profile?.address?.[field.key])}</Box>  
                                </Box>
                            </Grid>
                        )

                    }

                    else if (field.key=='owns_bank_account')
                    {
                        return(
                            <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                                <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                    {field.label}
                                </Box>
                                <Box>
                                    <Box  sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(trainingRequest?.[field.key])}</Box>  
                                </Box>
                            </Grid>
                        )

                    }
                    return(
                        <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                {field.label}
                            </Box>
                            <Box>
                                <Box  sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(profile?.[field.key])}</Box>  
                            </Box>
                        </Grid>
                    )
                   
                })}
            </Grid>

            <Box my={'2rem'} sx={{fontSize:'1.0rem',color:'#1576ec',borderLeft:'3px solid',fontWeight:'600',paddingLeft:'0.5rem'}}>
                Educational Information
            </Box>
            <Grid container sx={{width:'100%',marginBottom:'2rem'}} columnSpacing={'1.5rem'} rowSpacing={'2.5rem'}>
                {educationalKeys.map((field,index)=>{
                    
                    if (field.key =='name')
                    {
                        return(
                            <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                {field.label}
                            </Box>
                            <Box>
                                <Box  sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(businessProfile?.industry?.[field.key])}</Box>  
                            </Box>
                        </Grid>

                        )
                     }
                     else {
                        return(
                            <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                                <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                    {field.label}
                                </Box>
                                <Box>
                                    <Box  sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(profile?.[field.key])}</Box>  
                                </Box>
                            </Grid>
                        )

                     }
                })}
            </Grid>

           
            <Box my={'2rem'}  sx={{fontSize:'1.0rem',color:'#1576ec',borderLeft:'3px solid',fontWeight:'600',paddingLeft:'0.5rem',marginBottom:'2.5rem'}}>
                Training Request Information
            </Box>
            <Grid container sx={{width:'100%',marginBottom:'2rem'}} columnSpacing={'1.5rem'} rowSpacing={'2.5rem'}>
                {trainingRequestKeys.map((field,index)=>{
                    if (field.key == 'name')
                    {
                        <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                {field.label}
                            </Box>
                            <Box>
                                <Box sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(trainingRequest?.category?.[field?.key])}</Box>  
                            </Box>
                        </Grid>

                    }
                    else {
                    return (
                        <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                {field.label}
                            </Box>
                            <Box>
                                <Box sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(trainingRequest?.[field?.key])}</Box>  
                            </Box>
                        </Grid>
                    )
                    }
                })}
            </Grid>

            <Box my={'2rem'}  sx={{fontSize:'1.0rem',color:'#1576ec',borderLeft:'3px solid',fontWeight:'600',paddingLeft:'0.5rem',marginBottom:'2.5rem'}}>
                Entrepreneurial/Business Acumen
            </Box>
            <Grid container sx={{width:'100%',marginBottom:'2rem'}} columnSpacing={'1.5rem'} rowSpacing={'2.5rem'}>
                {businessAcumenKeys.map((field,index)=>{
                    return (
                        <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                {field.label}
                            </Box>
                            <Box>
                                <Box sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(trainingRequest?.[field?.key])}</Box>  
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>

            <Box my={'2rem'}  sx={{fontSize:'1.0rem',color:'#1576ec',borderLeft:'3px solid',fontWeight:'600',paddingLeft:'0.5rem',marginBottom:'2.5rem'}}>
                Financial Literacy
            </Box>
            <Grid container sx={{width:'100%',marginBottom:'2rem'}} columnSpacing={'1.5rem'} rowSpacing={'2.5rem'}>
                {financialLiteracyKeys.map((field,index)=>{
                    return (
                        <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                {field.label}
                            </Box>
                            <Box>
                                <Box sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(trainingRequest?.[field?.key])}</Box>  
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>

            <Box my={'2rem'}  sx={{fontSize:'1.0rem',color:'#2A3B8F',borderLeft:'3px solid',fontWeight:'600',paddingLeft:'0.5rem',marginBottom:'2.5rem'}}>
                Other Information
            </Box>
            <Grid container sx={{width:'100%',marginBottom:'2rem'}} columnSpacing={'1.5rem'} rowSpacing={'2.5rem'}>
                {otherInformationKeys.map((field,index)=>{
                    return (
                        <Grid item xs = {4} sx ={{display:'flex',flexDirection:'column'}}>
                            <Box sx={{marginBottom:'1.5rem',height:'1rem'}}>
                                {field.label}
                            </Box>
                            <Box>
                                <Box sx={{fontSize:'0.9rem',fontWeight:'600'}}>{returnDefaultValue(profile?.[field?.key])}</Box>  
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>

        </Box>
     }

    </Box>
  )
}

ApplicationOverview.propTypes = {
    userData:propTypes.object
}

export default ApplicationOverview