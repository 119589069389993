import CheckIcon from "@components/buttons/CheckIcon";
import React from "react";
import { Stack } from "react-bootstrap";
// import line_39 from "../../../assets/line_39.svg";
import propTypes from "prop-types";

export default function ApplicationStatusCard({ data = {} }) {
  console.log(data);
  const style = {
    background: "white",
    borderRadius: "5px",
   
  };

  // const lineStyle = {
  //   height: "auto",
  //   width: "auto",
  // };

  return (
    <div
      className="small-shadow d-flex flex-row justify-content-between py-3 col-md-4"
      style={style}
    >
      <ApplicationStage
        isDone={data?.profile?.is_application_complete}
        title="Create Profile"
        stageName="Registration"
        stageInfo="Program Application"
        className={"ms-3"}
      />
      {/* <img style={lineStyle} src={line_39} />
      <ApplicationStage
        isDone={data.is_approved_for_selection}
        title="Selection"
        stageName="Selection Ongoing"
        stageInfo="Selection Approved"
      />
      <img style={lineStyle} src={line_39} />
      <ApplicationStage
        isDone={data.is_approved_for_training}
        title="Training"
        stageName="Ongoing basic"
        stageInfo="Training Passed"
      />
      <img style={lineStyle} src={line_39} />
      <ApplicationStage
        isDone={data.is_approved_for_grant}
        title="Grant Application"
        stageName="Application submitted"
        stageInfo="Grant Approved"
        className={"me-3"}
      /> */}
      <div className="vertical divider"></div>
    </div>
  );
}

ApplicationStatusCard.propTypes = {
  data: propTypes.object,
};

function ApplicationStage({ isDone, title, stageName, stageInfo, className }) {
  return (
    <div gap={2} className={`position-relative mt-3 ${className}`}>
      {isDone ? (
        <Stack gap={2} className="position-relative pb-xl-0 mx-xl-auto">
          <h6 className="fw-bold">{title}</h6>
          <div className="pt-2  px-2 text-wrap text-break">
            <CheckIcon /> {stageName}
          </div>
          <div className="ps-3 primary-text text-sm text-wrap ">
            <CheckIcon /> {stageInfo}
          </div>
        </Stack>
      ) : (
        <Stack gap={2} className="position-relative pb-xl-0 opacity-50 ">
          <h6 className="fw-bold text-muted">{title}</h6>
          <div className="pt-2 px-2">
            <span className="text-muted text-wrap text-break">{stageName}</span>
          </div>
          <div className="ps-3 text-muted text-wrap text-break">
            {stageInfo}
          </div>
        </Stack>
      )}
    </div>
  );
}

ApplicationStage.propTypes = {
  isDone: propTypes.bool,
  title: propTypes.string,
  stageName: propTypes.string,
  stageInfo: propTypes.string,
  className: propTypes.string,
};
