import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

import OnboardProgress from "@components/layout/navigation/OnboardProgressNav";
import { ToastContainer } from "react-toastify";
import BusinessAccumen from "@components/forms/Auth/Forms/BusinessAccumen";
import { useQuery } from "react-query";
import { getUserProfile, updateFullUserProfile } from "src/api/User/User";
import { useMutation } from "react-query";
import { updateUserTrainingData } from "src/api/Training/Training";
import { useNavigate } from "react-router-dom";
import { cleanArray, handleErrorMessage, messageAlert } from "@utils/helpers/functions";
import OnboardTopNav from "@components/layout/OnboardTopNav";
import constant from "@utils/constants/constants";


const BusniessAcumen = () => {
  let navigate = useNavigate();

  const mutation = useMutation(updateUserTrainingData);
  const profile_mutation = useMutation(updateFullUserProfile);

  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data-verifcation", getUserProfile);

  const trainingData = responseData?.data?.training_requests;
  const onboardingState = responseData?.data?.onboarding_data;
  const userId = responseData?.data?.user?.id;
  



  const training_data = trainingData
    ? trainingData[trainingData.length - 1]
    : {};
  
  var owns_a_business = responseData?.data?.profile?.owns_a_business;

  const state = {
    ...training_data,
    owns_a_business: owns_a_business
  };
  var onboarding_data = {...onboardingState, 'accumen':true}

  if (isLoading) return <h1>Loading...</h1>;

  if (error) return <h1>Error...</h1>;

  const submitData = (data) => {

    if (data) {
      var business_industries = [];
      constant.BUSINESS_TYPES.map((item) =>
        business_industries.push(data?.[item.value])
      );
      if(data.other_business){
        business_industries.push(data.other_business)
      }

      var tried_to_start_business_industry =
      business_industries.length > 0
        ? cleanArray(business_industries).toString()
        : "";

      if(tried_to_start_business_industry){
        data.tried_to_start_business_industry = tried_to_start_business_industry
      }

      mutation.mutate(
        { id: training_data.id, data },
        {
          onSuccess: () => {
            navigate("/onboarding/financial_literacy");
          },
          onError: (error) => {
            var errorMessage = handleErrorMessage(error?.response);
            messageAlert("error", errorMessage);
          },
        }
      );
      profile_mutation.mutate(
        { id:userId, onboarding_data:onboarding_data }
      );
    }
  };

  const navigateToPreviousPage = () => {
    navigate("/onboarding/training");
  };

  return (
    <Fragment>
      <ToastContainer />
      <OnboardTopNav/>
      <Row>
      <OnboardProgress page={4} />
      <Col md={9} sm={12}>
        
            <BusinessAccumen
              state={state}
              loading={mutation.isLoading}
              onSubmit={submitData}
              onPreviousButtonClicked={navigateToPreviousPage}
            />
         
      </Col>
      </Row>
    </Fragment>
  );
};

export default BusniessAcumen;
