import React, { useState } from "react";
import propTypes from "prop-types";
import { Button } from "react-bootstrap";

export default function SecondaryButton({
  target,
  label,
  onclick,
  isLoading,
  isDisabled,
  link,
}) {
  const [hover, setHover] = useState(false);
  const getButtonStyle = () => ({
    backgroundColor: hover ? "#1576EC" : "white",
    fontSize: "14px",
    borderRadius: "20px",
    border: "none",
    paddingLeft: "36px",
    paddingRight: "36px",
    color: hover ? "white" : "#4F4F4F",
  });

  const buttonText = label ? label : "Approve";
  const disabled = isDisabled ? "disabled" : "";

  return (
    <div>
      <Button
        className={`ms-2  pb-2 ${disabled}`}
        style={getButtonStyle()}
        aria-current="page"
        data-bs-toggle="modal"
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        data-bs-target={target ? target : ""}
        onClick={onclick ? onclick : null}
        href={link ? link : "#"}
      >
        {isLoading ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          buttonText
        )}
      </Button>
    </div>
  );
}

SecondaryButton.propTypes = {
  target: propTypes.string,
  label: propTypes.string,
  onclick: propTypes.func,
  isLoading: propTypes.bool,
  isDisabled: propTypes.bool,
  link: propTypes.string,
};
