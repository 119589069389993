import React, { Fragment, useEffect, useState } from "react";
import propTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import TextField from "@components/forms/TextField/TextField";
import { InputSelect } from "@components/select/Select";
import FormInput from "@components/forms/FormInput/FormInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MainButton from "@components/buttons/Buttons";
import { Required } from "@components/utils";
import { getDistricts, getRegions, getTrainingCategories } from "@utils/helpers/functions";
import { useQuery } from "react-query";
import constant from "@utils/constants/constants";

const validationSchema = yup.object({
});

export default function Training({
  disabled = false,
  state,
  colSize,
  onSubmit,
  onPreviousButtonClicked,
  loading,
}) {
  const { register, control, handleSubmit, watch } = useForm({
    resolver: yupResolver(validationSchema),
  });


  

  const {
    data: responseData,
  } = useQuery("trianing-categories", getTrainingCategories);
  const trainingCategories = responseData?.data;

  

  const {
    data: regionData,
  } = useQuery("regions", getRegions);
  const regions = regionData?.data;

  const [districts, setDistrict] = useState([])
  useEffect(()=>{
    if(state?.previous_vocational_master_location){
        handleGetDistricts(state?.previous_vocational_master_location?.split('-')[0])
    }
},[state?.previous_vocational_master_location]) 

async function handleGetDistricts(region) {
    let districts = await getDistricts(region)
    setDistrict(districts?.data)
}

  const [controlState, setControlState] = useState(state);
  function handleChange({ target }) {
    setControlState({ ...controlState, [target.name]:target.value });
  }

  let previous_entrepreneurial_skills = watch('previous_entrepreneurial_skills')?watch('previous_entrepreneurial_skills'):state?.previous_entrepreneurial_skills

  let category_multi = state?.category_multi?.split(',') || []
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex  align-items-center h-100">
        <Col md={colSize ? colSize : 8}>
          <TextField
            text={"Training Information"}
            className={"fs-5 fw-bold mb-5 text-center"}
          />

          {state?.owns_a_business &&
          <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                If you own a business, do you need any training to improve your business?
                <Required/>
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("desires_training_to_improve_business", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={true}
                    required
                    inline
                    defaultChecked={state?.desires_training_to_improve_business === true}
                    label="Yes"
                    name="desires_training_to_improve_business"
                    type="radio"
                    id={`inline-radio-3`}
                    
                  />
                  <Form.Check
                    {...register("desires_training_to_improve_business", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    value={false}
                    required
                    inline
                    label="No"
                    defaultChecked={state?.desires_training_to_improve_business === false}
                    name="desires_training_to_improve_business"
                    type="radio"
                    id={`inline-radio-4`}
                    
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>
                If yes, tick any applicable?
                {/* <sup className="text-danger">*</sup> */}
              </Form.Label>
              <Row>
                <Col>

                {trainingCategories?.map((data, index)=>(
                    <Form.Check
                    {...register(data?.name)}
                      key={index}
                      defaultChecked={category_multi.includes(data.name)}
                      value={data?.name}
                      label={data?.name}
                      name={data?.name}
                      type="checkbox"
                      id={`inline-radio-1`}
                      disabled={
                        disabled || !controlState?.desires_training_to_improve_business || ["false"].includes(controlState?.desires_training_to_improve_business)
                      }
                    />
                  ))}
                </Col>
              </Row>
              {watch('Other') === 'Other' && 
                  <Controller
                name="other_category"
                control={control}
                required
                render={({ field }) =>(
                <FormInput
                    {...field}
                    name="other_category"
                    type="text"
                    isRequired
                    defaultValue={state?.other_category}
                    isDisabled={disabled}
                    placeholder="Specify"
                  />)}/>}
            </Form.Group>

          </Row>
          }
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Have you had apprenticeship or skills training?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("has_previous_vocational_training", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    defaultChecked={
                      state?.has_previous_vocational_training === true
                    }
                    value={true}
                    required
                    inline
                    label="Yes"
                    disabled={disabled}
                    name="has_previous_vocational_training"
                    type="radio"
                    id={`inline-radio-1`}
                    onChange={handleChange}
                  />
                  <Form.Check
                    {...register("has_previous_vocational_training", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    defaultChecked={
                      state?.has_previous_vocational_training === false
                    }
                    value={false}
                    required
                    inline
                    label="No"
                    disabled={disabled}
                    name="has_previous_vocational_training"
                    type="radio"
                    id={`inline-radio-2`}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Controller
                name="previous_entrepreneurial_skills"
                control={control}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    title="If yes, what skill did you attain after the training?"
                    name="previous_entrepreneurial_skills"
                    options={constant.TRAINING_SKILLS}
                    isRequired={true}
                    isDisabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                    defaultSelect={state?.previous_entrepreneurial_skills}
                    defaultValue={state?.previous_entrepreneurial_skills}
                  />
                )}
              />
              {previous_entrepreneurial_skills === 'Others' &&
              <Controller
                name="other_previous_entrepreneurial_skills"
                control={control}
                render={({ field }) =>(
                <FormInput
                  {...field}
                  title={" "}
                  defaultValue={state?.other_previous_entrepreneurial_skills}
                  name="other_previous_entrepreneurial_skills"
                  isDisabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                  type="text"
                  placeholder="Specify"
                />
              )}/>}
            </Form.Group>
          </Row>
          <Row className="mb-5">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Who provided the training?
                <Required />
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("who_provided_training", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    defaultChecked={
                      state?.who_provided_training === 'institution'
                    }
                    value={'institution'}
                    required
                    inline
                    label="Institution"
                    disabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                    name="who_provided_training"
                    type="radio"
                    id={`inline-radio-1`}
                    onChange={handleChange}
                  />
                  <Form.Check
                    {...register("who_provided_training", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    defaultChecked={
                      state?.who_provided_training === 'master_craft_person'
                    }
                    value={'master_craft_person'}
                    required
                    inline
                    label="Master craft person"
                    disabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                    name="who_provided_training"
                    type="radio"
                    id={`inline-radio-2`}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </Form.Group>
            
            {controlState?.who_provided_training === 'institution' && 
            <Form.Group as={Col} md="6" className="mb-3">
              <Controller
                name="previous_vocational_master_name"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    title={`Name of Institution`}
                    isDisabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                    type="text"
                    placeholder="Name"
                    isRequired
                    defaultValue={state?.previous_vocational_master_name}
                  />
                )}
              />
            </Form.Group>
            }
            {controlState?.who_provided_training === 'master_craft_person' && 
              <Form.Group as={Col} md="6">
                <Form.Label>
                Location of Master craft person
                <Required />
                
              </Form.Label>
              <br/>
                <Controller
                  {...register("region", {
                    onChange: (e) => {
                    var region = e?.target?.value && JSON.parse(e?.target?.value)
                    handleGetDistricts(region?.id)}
                  })}
                    defaultValue={state?.previous_vocational_master_location?.split('-')[0]}
                    name="region"
                    control={control}
                    render={({ field }) =>(
                <InputSelect
                  {...field}
                  defaultSelect={state?.previous_vocational_master_location?.split('-')[0]}
                  isDisabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                  title="Region"
                  name="region"
                  options={regions}
                />)}/>

                <Controller
                  {...register("district")}
                    name="district"
                    defaultValue={state?.previous_vocational_master_location?.split('-')[1]}
                    control={control}
                    render={({ field }) =>(
                <InputSelect
                  {...field}
                  defaultSelect={state?.previous_vocational_master_location?.split('-')[1]}
                  isDisabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                  title="District"
                  name="district"
                  options={districts}
                />)}/>
              
              </Form.Group>
            }
            
          </Row>
          <Row className="mb-5">
            
          </Row>
          <Row className="mb-5">
          <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Do you have any certification?
                <Required/>
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("has_vocational_certification", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    defaultChecked={state?.has_vocational_certification === true}
                    value={true}
                    required
                    inline
                    label="Yes"
                    disabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                    name="has_vocational_certification"
                    type="radio"
                    id={`inline-radio-1`}

                  />
                  <Form.Check
                    {...register("has_vocational_certification", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    defaultChecked={state?.has_vocational_certification === false}
                    value={false}
                    required
                    inline
                    label="No"
                    disabled={disabled || ['false', false].includes(controlState?.has_previous_vocational_training)}
                    name="has_vocational_certification"
                    type="radio"
                    id={`inline-radio-2`}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>
                If Yes, please specify
              </Form.Label>
              <Row>
                <Col>

                {constant.VOCATIONAL_CERTS?.map((data, index)=>(
                    <Form.Check
                      {...register(data.value, {
                        onChange: (e) => {handleChange(e)},
                      })}
                      key={index}
                      value={data.value}
                      label={data.label}
                      name={data.value}
                      defaultChecked={state?.previous_vocational_certificate?.split(',')?.includes(data.value)}
                      type="checkbox"
                      id={`inline-radio-1`}
                      disabled={
                        disabled || ['false', false].includes(controlState?.has_previous_vocational_training) || ['false', false].includes(controlState?.has_vocational_certification)
                      }
                    />
                  ))}
                </Col>
              </Row>
              {watch('other_previous_vocational_certificate_check') && 
                  <Controller
                name="other_previous_vocational_certificate"
                control={control}
                required
                render={({ field }) =>(
                <FormInput
                    {...field}
                    name="other_previous_vocational_certificate"
                    type="text"
                    isRequired
                    defaultValue={state?.other_previous_vocational_certificate}
                    isDisabled={disabled}
                    placeholder="Specify"
                  />)}/>}
            </Form.Group>
            
          </Row>
          {state?.owns_a_business === false && <Fragment>
          <Row className="mb-5">
          <Form.Group as={Col} md="6">
              <Form.Label>
                Would you like to be employed or start your own business?
                <Required/>
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("desires_employment")}
                    value={true}
                    required
                    inline
                    disabled={disabled}
                    defaultChecked={state?.desires_employment === true}
                    label="I would like to be employed"
                    name="desires_employment"
                    type="radio"
                    id={`inline-radio-1`}
                  />
                  <Form.Check
                    {...register("desires_employment")}
                    value={false}
                    required
                    inline
                    defaultChecked={state?.desires_employment === false}
                    disabled={disabled}
                    label="I would like to start a business"
                    name="desires_employment"
                    type="radio"
                    id={`inline-radio-2`}
                  />
                </Col>
              </Row>
            </Form.Group>

          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Can you start your business without any support?
                <Required/>
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    {...register("can_start_business_without_support", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    defaultChecked={state?.can_start_business_without_support === true}
                    value={true}
                    required
                    inline
                    label="Yes"
                    disabled={disabled}
                    name="can_start_business_without_support"
                    type="radio"
                    id={`inline-radio-1`}

                  />
                  <Form.Check
                    {...register("can_start_business_without_support", {
                      onChange: (e) => {handleChange(e)},
                    })}
                    defaultChecked={state?.can_start_business_without_support === false}
                    value={false}
                    required
                    inline
                    label="No"
                    disabled={disabled}
                    name="can_start_business_without_support"
                    type="radio"
                    id={`inline-radio-2`}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>
                If No, what kind of support will you require?
              </Form.Label>
              <Row>
                <Col>

                {constant.BUSINESS_SUPPORT?.map((data, index)=>(
                    <Form.Check
                      {...register(data.value, {
                        onChange: (e) => {handleChange(e)},
                      })}
                      key={index}
                      value={data.value}
                      label={data.label}
                      name={data.value}
                      defaultChecked={state?.business_support_required?.split(',')?.includes(data.value)}
                      type="checkbox"
                      id={`inline-radio-1`}
                      disabled={
                        disabled || ['true', true].includes(controlState?.can_start_business_without_support)
                      }
                    />
                  ))}
                </Col>
              </Row>
              <Controller
                name="other_support"
                control={control}
                render={({ field }) =>(
                <FormInput
                  {...field}
                  title={" "}
                  name="other_skills"
                  isDisabled={disabled || ['true', true].includes(controlState?.can_start_business_without_support)}
                  type="text"
                  placeholder="Others"
                />
              )}/>
            </Form.Group>

          </Row>
          </Fragment>}
          

         
            {onSubmit && (
              <div className="row mt-5 mb-3">
                <Col md={4} className="mb-3">
                  <MainButton
                    text={"Previous"}
                    type={"button"}
                    onClick={onPreviousButtonClicked}
                    variant="outline-secondary"
                    size="lg"
                    className="fs-6"
                  />
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <MainButton
                    text={"Next"}
                    type={"submit"}
                    variant="primary"
                    size="lg"
                    className="fs-6"
                    isLoading={loading}
                  />
                </Col>
              </div>
            )}
        </Col>
      </Row>
    </Form>
  );
}

Training.propTypes = {
  disabled: propTypes.bool,
  state: propTypes.object,
  colSize: propTypes.number,
  onSubmit: propTypes.func,
  onPreviousButtonClicked: propTypes.func,
  loading: propTypes.bool
};
