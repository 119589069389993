import React from "react";
import propTypes from "prop-types";
import { Nav } from "react-bootstrap";

export default function BottomNavItem({ title, isHeader, link, icon }) {
  const style = {
    fontSize: "14px",
    color: "#FFFFFF",
    lineHeight: "21px",
    paddingTop: "0px",
    marginTop: "4px",
    marginBottom: "4px",
  };
  return (
    <Nav.Link className={`py-0 ${isHeader && 'mt-4'}`} style={style} href={link ? link : "/home"}>
      {icon ? <i className={icon}></i> : null}{" "}
      {isHeader ? <span className="fw-bold">{title}</span> : title}
    </Nav.Link>
  );
}

BottomNavItem.propTypes = {
  icon: propTypes.string,
  title: propTypes.string.isRequired,
  isHeader: propTypes.bool,
  link: propTypes.string,
};
