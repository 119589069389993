import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

import OnboardProgress from "@components/layout/navigation/OnboardProgressNav";
import { ToastContainer } from "react-toastify";
import Education from "@components/forms/Auth/Forms/Education";
import {
  cleanArray,
  handleErrorMessage,
  messageAlert,
  storeLocalStorage,
} from "@utils/helpers/functions";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { getUserProfile, updateFullUserProfile } from "src/api/User/User";
import constant from "@utils/constants/constants";
import OnboardTopNav from "@components/layout/OnboardTopNav";

const EducationalInfo = () => {
  let navigate = useNavigate();

  const mutation = useMutation(updateFullUserProfile);

  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data-verifcation", getUserProfile);

  const state = responseData?.data?.profile;
  const otherIndustry = responseData?.data?.business_profile;
  const onboardingState = responseData?.data?.onboarding_data;
  const fullData = {
    ...state,
    other_industry: otherIndustry?.other_industry,
    industry: otherIndustry?.industry,
    id: responseData?.data?.user?.id,
  };

  if (isLoading) return <h1>Loading...</h1>;

  if (error) return <h1>Error...</h1>;

  const submitData = (data) => {
    var onboarding_data = {...onboardingState, 'education':true}

    var vocational_skills = [];
    constant.VOCATIONAL_SKILLS.map((skill) =>
      vocational_skills.push(data?.[skill.value])
    );

    data.previous_vocational_skills =
      vocational_skills.length > 0
        ? cleanArray(vocational_skills).toString()
        : "";
    var business_profile = {};
    business_profile.other_industry = data?.other_industry;
    if (data?.industry) {
      business_profile.industry = JSON.parse(data?.industry);
    }
    mutation.mutate(
      {
        id: responseData?.data?.user?.id,
        profile: data,
        business_profile: business_profile,
        onboarding_data:onboarding_data,
      },
      {
        onSuccess: (response) => {
          storeLocalStorage('profileData', JSON.stringify(response?.data))
          navigate("/onboarding/training");
        },
        onError: (error) => {
          var errorMessage = handleErrorMessage(error?.response);
          messageAlert("error", errorMessage);
        },
      }
    );
  };

  const navigateToPreviousPage = () => {
    navigate("/onboarding");
  };

  return (
    <Fragment>
      <ToastContainer />
      <OnboardTopNav/>
      <Row>
      <OnboardProgress page={2} />
      <Col md={9} sm={12}>
        
        <Education
          state={fullData}
          onSubmit={submitData}
          onPreviousButtonClicked={navigateToPreviousPage}
          loading={mutation.isLoading}
        />
          
      </Col>
      </Row>
    </Fragment>
  );
};

export default EducationalInfo;
