import React from "react";
import { Row, Col, Container, Ratio, ListGroup } from "react-bootstrap";
import HowToApplyCard from "./HowToApplyCard";
import Divider from "@components/layout/Divider";
import Hero from "@components/layout/navigation/hero/Hero";
import SecondaryButton from "@components/buttons/SecondaryButton";
import hero_imgae from "@images/bg/jas_hero_5.jpg";
import { propTypes } from "react-bootstrap/esm/Image";
import DefaultButton from "@components/buttons/DefaultButton";
import GEA from "@images/GEA.png";
import mof from "@images/logo/coat_of_arms.png";
import NEIP from "@images/logo/World-Bank-2_0.jpeg";
// import jas_hero_10 from "@images/bg/jas_10.svg";
import ListItem from "@components/buttons/ListItem";
import image_1 from "@images/icons/image_1.svg";
import image_2 from "@images/icons/image_2.svg";
import image_3 from "@images/icons/image_3.svg";
import image_4 from "@images/icons/image_4.svg";
import image_6 from "@images/icons/image_6.svg";
import smiling_guy from "@images/bg/smiling_guy.png";
export default function HomePage() {
  const divStyle = {
    marginTop: "100px",
  };
  return (
    <>
      <div className="">
        <Hero />
      </div>
      <Container>
        <div className="mb-5">
          <ApplicationLinksCard />
        </div>
      </Container>
      <Container className="mt-5">
        <div className="mt-5">
          <div style={divStyle}>
            <WhatIsJobsAndSkills />
          </div>
        </div>
      </Container>
      <div style={{ marginTop: "150px" }}>
        <HowToApply />
      </div>
      <Container>
        <HomePageVideo />
      </Container>
      <Container style={divStyle}>
        <ApplicationRequirements />
      </Container>
      <Container>
        <div style={divStyle}>
          <QualificationSection />
        </div>
      </Container>
      <Container fluid className="my-4">
        <div style={divStyle}>
          {" "}
          <ActionSection />{" "}
        </div>
      </Container>
      <Container className="my-5">
        <div className="d-flex justify-content-center text-center">
          <div className="my-4">
            <h1>Sponsors</h1>
            <p>
              The following organizations are sponsoring and implementing agencies of the Ghana Jobs and Skills Project
            </p>
          </div>
        </div>
        <div className="d-md-flex justify-content-between mb-4 ms-4">
          <div className="text-center">
            <img src={GEA} width style={{ width: "180px", height: "180px" }} />
            <h4 className="fw-bold mt-3">The Ghana Enterprises Agency</h4>
          </div>
          <div className="text-center">
            <img src={mof} style={{ width: "180px", height: "180px" }} />
            <h4 className="fw-bold mt-3">The Government of Ghana</h4>
          </div>
          <div className="text-center">
            <img src={NEIP} style={{ width: "280px", height: "180px" }} />
            <h4 className="fw-bold mt-3">The World Bank</h4>
          </div>
        </div>
      </Container>
    </>
  );

  function ApplicationRequirements() {
    return (
      <div>
        <div className="text-center">
          <h3 className="fw-bold">What to expect from the Project</h3>
          <p className="mt-4">
          The Ghana Jobs and Skills Project will follow 5 simple steps
          </p>
        </div>
        <div className="row text-center d-flex justify-content-center mt-5">
          <CardCol
            image={image_1}
            title={"Application/Registration"}
            text="Registration and application for the project is targeted at Ghanaians from the ages of 18 to 40 with at least Basic Education Certificate Examination qualification and willing to start a business. School dropouts already in a business would be considered for this project as well. Special attention would be given to women and Persons with Disabilities (PWD)"
          />
          <CardCol
            image={image_2}
            title={"Readiness And Needs Assessment"}
            text="Shortlisted applicants would be invited by GEA Officers in their respective locations for a readiness screening/needs assessment exercise for verification of data provided by applicants and identification of needs for Entrepreneurship Development."
          />
          <CardCol
            image={image_4}
            title={"Entrepreneurship Training"}
            text="A district based and non-residential Entrepreneurship Training would be conducted at 3 levels: basic, intermediate, and advanced using an experiential participatory approach conducive for adult learning"
          />
        </div>
        <div className="row text-center d-flex justify-content-center mt-5">
          <CardCol
            image={image_3}
            title={"Coaching and Mentoring"}
            text="The Coaching and Mentoring support would be provided to beneficiaries who progress to the advance level to finalize their business plans, meeting of licensing and registration requirements, testing of products on the market. Short duration internship, mentoring and guidance sessions would be arranged for some selected beneficiaries"
          />
          <CardCol
            image={image_6}
            title={"Competitive Start-up Grants"}
            text="5,000 selected youth (individual or self-formed groups) who successfully undergo training up to the intermediate and advance levels would be eligible for Business Startup Grants. They must demonstrate high potential to succeed in business, and operating in any sector aside from the extraction of natural resources"
          />
        </div>
      </div>
    );
  }

  function ApplicationLinksCard() {
    return (
      <Row className="d-flex justify-content-center position-relative p-0 m-0 mb-5">
        <Col
          xs={8}
          className="position-absolute top-0 start-50 translate-middle d-none d-md-block "
          style={{
            marginBottom: "300px",
          }}
        >
          <div
            className="application-card-shadow d-flex justify-content-center p-4"
            style={{
              zIndex: "10",
              background: "#FFF",
              borderRadius: "12px",
            }}
          >
            <div className="text-center">
              <h6 className="fw-bold">Already started your application?</h6>
              <div className="my-4">
                You can continue your application or check the status of your
                submitted application
              </div>
              <div className="d-flex justify-content-center ">
                <DefaultButton label="Continue Application" link="/login" />

                <div className="ms-4">
                  <DefaultButton
                    label="Check Application Status"
                    link="/login"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  function QualificationSection() {
    return (
      <Row className="my-5">
        <Col sm={12} md={6}>
          <div>
            <Divider />
            <h3>Who qualifies to apply?</h3>
            <p className="">
              The following category of youth will be targeted:
            </p>
          </div>
        </Col>
        <Col className="mt-4" sm={12} md={6}>
          <ListGroup variant="flush" className="text-md">
            <ListItem text="Persons between the ages 18 and 40" />
            <ListItem text="Minimum of Basic Education Certificate Examination" />
            <ListItem text="Willingness to start a business" />
            <ListItem text="School dropouts already in a business would be considered" />
            <ListItem text="Special attention would be given to women and Persons with Disabilities (PWD)" />
          </ListGroup>
        </Col>
      </Row>
    );
  }

  function ActionSection() {
    var sectionStyle = {
      backgroundImage: `linear-gradient(rgba(12, 12, 12, 0.397), rgba(12, 12, 12, 0.479)), url(${hero_imgae})`,
      backgroundPosition: "10% 25%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      height: "350px",
      color: "white",
    };
    return (
      <Row style={sectionStyle} className="my-5">
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <h2 className="white-text fw-bold">
            Are you Ready to take this step?
          </h2>
          <div className="mt-4">
            <SecondaryButton label="Apply Now" link="/get_started" />
          </div>
        </Col>
      </Row>
    );
  }

  function WhatIsJobsAndSkills() {
    return (
      <Row
        className="d-flex justify-content-between"
        style={{
          marginTop: "180px",
        }}
      >
        <Col sm={12} md={6}>
          <div>
            <Divider />
            <h1 className="mb-4">
              About the Ghana <br />Jobs and Skills Project
            </h1>
            <p
              style={{
                
                fontSize: "15px",
                lineHeight: "22px",
              }}
            >
              The World Bank is providing financing and technical support to the
              Government of Ghana (GoG) for the implementation of the Ghana Jobs
              and Skills Project. The Project is considered as one of the main
              forms of support for the government’s top-priority agenda of
              upgrading skills among the country’s population, creating more and
              better-quality jobs, and improving job outcomes for youth. It
              connects success in these areas to state stability, social
              cohesion, and economic inclusion.
            </p>
          </div>
          <div className="mt-4">
            <DefaultButton label="Read More" link="/about" />
          </div>
        </Col>
        <Col sm={12} md={6}>
          {/* <div className="position-relative">
            <div className="bg-jas-info-main"></div>
            <div className="bg-jas-info-sub"> </div>
            <div className="bg-jas-info-img">
              <img className="bg-jas-info-img" src={jas_hero_10} />
            </div>
          </div> */}
          <div className="d-flex justify-content-center">
            <img
              className=""
              style={{
                width: "20rem",
              }}
              src={smiling_guy}
            />
          </div>
        </Col>
      </Row>
    );
  }

  function HowToApply() {
    return (
      <Row className="m-0">
        <Col className="m-0 p-0">
          <div
            className="text-center"
            style={{
              paddingTop: "60px",
              paddingBottom: "200px",
              paddingLeft: "100px",
              paddingRight: "100px",
              lineHeight: "22px",

              background: "#F1F4FF",
            }}
          >
            <p
              className="lh-base"
              style={{
                fontSize: "15px",
              }}
            >
              All advanced training participants will receive mentoring and
              coaching support, which encompasses guidance to prepare business
              plans, meet business registration and licensing requirements, and
              connect with input and output markets. Short duration internship,
              mentoring and guidance sessions would be arranged for some
              selected beneficiaries
            </p>
            <p
              className="lh-base"
              style={{
                fontSize: "15px",
              }}
            >
              5,000 selected youth who successfully undergo training up to the
              intermediate level would be eligible for Business Startup Grants.
              These grants will be available to individuals and self-formed
              groups, majority-owned by Ghanaians, with high potential to
              succeed in business, and operating in any of the
              non-resource-based sectors
            </p>
          </div>
        </Col>
      </Row>
    );
  }
}

function HomePageVideo() {
  return (
    <div
      className="d-flex justify-content-center"
      style={{
        marginTop: "-160px",
      }}
    >
      <div
        className=""
        style={{ width: "710px", height: "auto", borderRadius: "12px" }}
      >
        <Ratio aspectRatio="16x9">
          <iframe
            style={{
              borderRadius: "8px",
            }}
            width="315"
            height="315"
            src="https://www.youtube.com/embed/6aXPRhtyjug"
            title="YouTube video player"
            frameGorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Ratio>
      </div>
    </div>
  );
}

function CardCol({ image, title, text }) {
  return (
    <Col sm={12} md={4}>
      <HowToApplyCard
        image={image ? image : null}
        title={title ? title : ""}
        text={text}
        style={{ zIndex: "1" }}
        className="positon-relative"
      />
    </Col>
  );
}
CardCol.propTypes = {
  image: propTypes.string,
  title: propTypes.string,
  text: propTypes.string,
};

HomePage.propTypes = {
  setHomePage: propTypes.func,
};
