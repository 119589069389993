/*eslint-disable*/
import MainButton from "@components/buttons/Buttons";
import useGetProfileInfo from "@utils/hooks/useGetProfileInfo";
import React from "react";
import { Badge, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getUserProfile } from "src/api/User/User";
import ProfileDetailsInfo from "./ProfileDetailsInfo";
import ApplicationOverview from "@views/ProfileNew/ApplicationOverview";
import { useGetCertificates } from "@utils/hooks/useGetProfileInfo";
import { Box } from "@mui/material";
import EllipsisText from "react-ellipsis-text";
import FilePresentIcon from '@mui/icons-material/FilePresent';


export default function UserProfile() {
    const iconStyle = {
        borderRadius:'50%',
        backgroundColor:' #ABB3BF',
        alignItems:'center',
        justifyContent:'center',
        width:'5rem',
        height:'5rem',
        marginRight:'1.3rem',
        fontWeight:'600',
        display:'flex'
    
    }
    
  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data", getUserProfile);

  const { certificates, isLoading: isLoadingCert } = useGetCertificates();

  const parseApprovalString = (string) => 
  {
    //pending_application_approval, approved_application_approval, approved_application_training, super_admin
    let bg_style = '';
    let status = '';

    switch(string)
    {
      case 'pending_application_approval':
        bg_style = 'warning';
        status = 'Pending Approval';
        break;
      case 'approved_application_approval':
        bg_style = 'success';
        status = 'Approved for Interview';
        break;
      case 'approved_application_training':
        bg_style = 'success';
        status = 'Approved for Training' ;
        break;
      default:
        bg_style = 'warning';
        status = 'Pending';
    }
    return(
      <Badge bg={bg_style} text="white">
        {status}
      </Badge>
    )

  }

  const data = responseData?.data;
  console.log(data,'35');
  if (isLoading)
    return (
      <Container className="p-4">
        <h2 className="mt-4 me-4">Loading...</h2>;
      </Container>
    );

  if (error) return "An error has occurred: " + error.message;

  return (
    <section>
     
      <Row className="mx-4 my-4">
        <Col xs={12} xl={8}>
            <div className="left d-flex" >
                    <div className="left" style={iconStyle}>
                        <div style={{color:'white', fontSize:'2rem',}}>
                        {data?.user?.first_name?.split('')[0]}
                        </div>
                    </div>
                    <div className="right flex flex-c" style={{justifyContent:'center'}}>
                        <div className="top flex" style={{marginBottom:'0.5rem',fontWeight:'600'}}>
                            <div style={{fontSize:'1.3rem',marginRight:'0.6rem',marginTop:'1.3rem'}}>
                                {data?.user?.first_name} {data?.user?.last_name}
                            </div>
                            
                        </div>
                        <div className="bottom flex">
                            <div style={{color:'gray',fontSize:'0.8rem',marginRight:'0.5rem'}}>Application{' '} 
                            {parseApprovalString(data?.application_process_status)}
                            </div>
                        </div>
                    </div>
                </div>
        </Col>

        <Col className="d-flex justify-content-start flex-column ms-4 mt-lg-4 mt-xl-none">
          <Row>
                {/* <Col xs={3}>
                    <MainButton text="Edit" dark />
                </Col> */}
                <Col xs={8}>
                    <Link to="/onboarding/application_pdf" target="_blank" className="text-decoration-none">
                        <MainButton text="Download Application PDF" dark />
                    </Link>
                </Col>
          </Row>
        </Col>
      </Row>
      <Row className="top d-flex mx-4 my-4" style={{borderBottom:'1px solid #EBEFF2',paddingBottom:'2rem'}}></Row>
      <Row className="mx-4 my-4 mt-2">
        <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example" className="mb-3" variant="pills">
            <Tab.Pane eventKey="overview" title="Overview" tabClassName="fw-bold">
              <ApplicationOverview userData={data}/>
            </Tab.Pane>

            <Tab.Pane eventKey="documents" title="Documents" tabClassName="fw-bold">
               <Box>
               {certificates?.map((certificate)=>{
                  let fileName = new URL(certificate?.file)?.pathname.split('/')[2]; 
                  let fileType = fileName.split('.')[1];
                  fileName = `${fileName.split('.')[0]}.${fileType}`;
                    
                  if (fileType==='jpg'||fileType==='png'){
                    return(
                      <Box mx={'0.5rem'} my={'1rem'} sx={{width:'100px',display:'flex',flexDirection:'column'}}>
                        <Box sx={{
                          width:'100%',
                          height:'100px',
                          backgroundImage:`url(${certificate?.file})`,
                          backgroundSize:'cover',
                          backgroundPosition:'center',
                          position:'relative'}} >
                          {/* <Box 
                           onClick={()=>{deleteFiles(certificate)}}
                          className="delete-file-btn" sx={{position:'absolute',top:'10px',left:'5px',background:'red',padding:'0.1rem',borderRadius:'0.5rem'}}>
                              <DeleteForeverIcon sx={{color:'white'}}/>
                          </Box> */}
                        </Box>
                        <Box mt={'0.5rem'} sx={{width:'100px'}}>
                          <a style={{textDecoration:'none',color:'inherit'}} href={`${certificate?.file}`} target="_blank">
                            <EllipsisText text={fileName} length={'10'} />
                          </a>
                        </Box>
                      </Box>
                    )
                  }
                    return(
                      <Box my={'1rem'} mx={'0.5rem'} sx={{width:'100px',display:'flex',flexDirection:'column'}}>
                      <Box sx={{
                        width:'100%',
                        height:'100px',
                        borderRadius:'0.5rem',
                        backgroundColor:'#e6e6e6',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        position:'relative'
                        }}>
                           {/* <Box 
                           onClick={()=>{deleteFiles(certificate)}}
                           className="delete-file-btn" sx={{position:'absolute',top:'10px',left:'5px',background:'red',padding:'0.1rem',borderRadius:'0.5rem'}}>
                              <DeleteForeverIcon sx={{color:'white'}}/>
                          </Box> */}
                          <FilePresentIcon sx={{color:'gray'}}/>
                      </Box>
                      <Box mt={'0.5rem'} sx={{width:'100px'}}>
                        <a style={{textDecoration:'none',color:'inherit'}} href={`${certificate?.file}`} target="_blank">
                          <EllipsisText text={fileName} length={'10'} />
                        </a>
                        
                      </Box>
                    </Box>
                  )
                })}
               </Box>
            </Tab.Pane>
            </Tabs>
      </Row>
    </section>
  );
}
