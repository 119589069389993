import React, { Fragment } from "react";
import { TextField } from "@components/forms";
import AuthLayout from "@components/layout/AuthLayout";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginForm from "@components/forms/Auth/LoginForm";

const Login = () =>{
    return(
        <AuthLayout>
            <Col sm={6}>
                <Row className="mb-5">
                    <Col>
                        <TextField text={<Fragment>New here?<Link to="/get_started" className="text-decoration-none mx-1"> Start application</Link></Fragment>} className="py-5 px-5 float-end fw-bold mb-5"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LoginForm/>
                    </Col>
                </Row>
            </Col>
        </AuthLayout>
    )
}


export default Login