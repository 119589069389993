import React from "react";
import { Col, Row} from "react-bootstrap";
import OnboardLayout from "@components/layout/OnboardLayout";


import OnboardProgress from "@components/layout/navigation/OnboardProgressNav";
import { ToastContainer } from "react-toastify";
import SignUpForm from "@components/forms/Auth/SignUpForm";
import OnboardTopNav from "@components/layout/OnboardTopNav";


const Signup = () =>{

    return(
        <OnboardLayout>
            <ToastContainer/>
            <OnboardTopNav/>
            <Row>
                <OnboardProgress page={1}/>
                <Col md={9} sm={12} >
                    <SignUpForm/>  
                </Col>
            </Row>
            
        </OnboardLayout>
    )
}


export default Signup