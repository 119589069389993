import HeroSection from "@components/layout/layouts/StaticPagesBaseLayout/HeroSection";
import React from "react";
import {
  Container,
  Tab,
  ListGroup,
  Row,
  Col,
  Accordion,
} from "react-bootstrap";
import hero_img from "@images/bg/jas_hero_8.jpg";
import constants from "@utils/constants/FAQ_Constants";
import propTypes from "prop-types";

export default function FAQ() {
  return (
    <>
      <HeroSection img={hero_img} title="Frequently Asked Questions" />
      <Container className="my-5">
        <SideNav />
      </Container>
    </>
  );

  function SideNav() {
    const style = {
      borderRight: "0.5px solid #C4C4C4",
    };

    return (
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#edu">
        <Row>
          <Col sm={4} style={style}>
            <ListGroup variant="flush" className="list-group">
              <ListGroup.Item action href="#edu">
                ABOUT THE APPLICATION PROCESS
              </ListGroup.Item>
              <ListGroup.Item action href="#training">
                ABOUT THE ENTREPRENEURSHIP TRAINING
              </ListGroup.Item>
              <ListGroup.Item action href="#support">
                ABOUT THE COMPETITIVE STARTUP GRANTS
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="#edu">
                <DropdownsEducation />
              </Tab.Pane>
              <Tab.Pane eventKey="#training">
                <DropdownsTraining />
              </Tab.Pane>
              <Tab.Pane eventKey="#support">
                <DropdownsGrantSuppport />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

function DropdownsEducation() {
  return (
    <Accordion defaultActiveKey="0" flush>
      {constants.FREQUENTYLY_ASKED_QUESTIONS_APPLICATION_PROCESS.map(
        (item, index) => {
          return (
            <Accordion.Item key={index} eventKey={String(index)}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>
                <NewlineText text={item.answer} />
              </Accordion.Body>
            </Accordion.Item>
          );
        }
      )}
    </Accordion>
  );
}

function DropdownsTraining() {
  return (
    <Accordion defaultActiveKey="1" flush>
      {constants.FREQUENTYLY_ASKED_QUESTIONS_ENTREPRENEURSHIP_TRAINING.map(
        (item, index) => {
          return (
            <Accordion.Item key={index} eventKey={String(index)}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>
                <NewlineText text={item.answer} />
              </Accordion.Body>
            </Accordion.Item>
          );
        }
      )}
    </Accordion>
  );
}

function DropdownsGrantSuppport() {
  return (
    <Accordion defaultActiveKey="1" flush>
      {constants.FREQUENTYLY_ASKED_QUESTIONS_COMPETITIVE_STARTUP_GRANTS.map(
        (item, index) => {
          return (
            <Accordion.Item key={index} eventKey={String(index)}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>
                <NewlineText text={item.answer} />
              </Accordion.Body>
            </Accordion.Item>
          );
        }
      )}
    </Accordion>
  );
}

function NewlineText(props) {
  const text = props.text;
  const newText = text.split("\n").map((str, idx) => <p key={idx}>{str}</p>);

  return newText;
}

NewlineText.propTypes = {
  text: propTypes.string,
};
