import DashboardNavbar from "@components/layout/navigation/dashbaordNavbar/DashboardNavbar";
import MainSidebar from "@components/layout/navigation/MainSidebar/MainSidebar";
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { refreshToken } from "src/utils/helpers/functions";

export default function DashboardBaseLayout() {
  const [refresh, setRefresh] = useState(1);
  useEffect(() => {
    refreshToken();
  }, [refresh]);
  setTimeout(function () {
    setRefresh(refresh + 1);
  }, 240000);

  const style = {
    height: "100%",
    minHeight: "100vh",
  };
  return (
    <Container fluid className="" style={style}>
      <Row className="">
        <Col
          xs={4}
          lg={2}
          as={"div"}
          className="p-0"
          style={{ background: "#F9FAFD" }}
        >
          <MainSidebar />
        </Col>
        <Col xs={8} lg={10} style={{ background: "#F9FAFD" }} className="p-0 ">
          <DashboardNavbar />
          <div className="">
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
