import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TextField } from "..";
import propTypes from "prop-types";
import { InputSelect } from "@components/select/Select";
import constant from "src/utils/constants/constants";
import FormInput from "../FormInput/FormInput";
import MainButton, { ButtonGutter } from "@components/buttons/Buttons";
import { connect } from "react-redux";
import { updateUserProfile } from "src/api/User/User";
import { pickFieldValues } from "src/utils/helpers/functions";


function LoanBusinessInfoForm({updateUserProfile, loading}){
    const user = JSON.parse(window.localStorage.getItem('user'))
    const [state, setState] = useState(user?.business_profile)
    function handleChange({target}){
        setState({...state, [target.name]:target.value})
    }

    function handleSubmit(e){
        e.preventDefault();
        const userProfile ={}
        if(user){
            userProfile.business_profile = pickFieldValues(['operational_stage', 'other_association', 'other_industry'], state)
            userProfile.onboarding_data = {loan:{...user?.onboarding_data?.loan, business_info:true}}
            updateUserProfile(user?.user?.id, userProfile, '/loan_application/business_location')
        }
    }
    
    return(
        <Form as={Col} md={12}>
            <Row className="mb-4">
                <Col md={8} className="text-center">
                    <TextField text="Buisness Information" className={'fs-5 fw-bold mb-3'}/>
                    <TextField text={'Tell us about your business'}/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <InputSelect onChange={handleChange} 
                        defaultSelect={state?.operational_stage}
                        defaultValue={state?.operational_stage}
                        title="Stage of Operation" 
                        name="operational_stage" 
                        options={constant.OPERATIONAL_STAGE} 
                        isRequired={true}/>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Are you part of an Association or Group?<sup className="text-danger">*</sup></Form.Label>
                    <Row>
                        <Col>
                            <Form.Check
                                value="yes"
                                required
                                inline
                                label="Yes"
                                name="association"
                                type="radio"
                                id={`inline-radio-1`}
                                // onChange={handleChange}
                            />
                            <Form.Check
                                value="no"
                                required
                                inline
                                label="No"
                                name="association"
                                type="radio"
                                id={`inline-radio-2`}
                                // onChange={handleChange}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <FormInput title="Name of Association or Group" name="association"  type="text" placeholder="Name of Association or Group" isRequired={true}/>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <FormInput title="Other(please specify)" name="other_association" defaultValue={state?.other_association} onChange={handleChange} type="text" placeholder="Specify"/>
                </Form.Group>
            </Row>
            <Row className="mb-5">
                <Form.Group as={Col} md="6">
                    <InputSelect  defaultSelect="Select Option" title="Industry Sector" name="industry" options={constant.OPERATIONAL_STAGE} isRequired={true}/>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <FormInput title="Other(please specify)" name="other_industry" defaultValue={state?.other_industry}  onChange={handleChange} type="text" placeholder="Specify"/>
                </Form.Group>
            </Row>
            <Row>
                <ButtonGutter>
                    <Col md={4}>
                        <MainButton text={'Previous'} type={'submit'} variant="outline-secondary" size="lg" className="fs-6" isDisabled={true}/>
                    </Col>
                    <Col md={{ span: 4, offset: 4 }}>
                        <MainButton text={'Next'} type={'submit'} onClick={handleSubmit} isLoading={loading} variant="primary" size="lg"  className="fs-6" isDisabled={!(state?.operational_stage)}/>
                    </Col>
                </ButtonGutter>   
            </Row>
        </Form>
    )
}
LoanBusinessInfoForm.propTypes = {
    handleChange:propTypes.func,
    updateUserProfile:propTypes.func,
    loading:propTypes.bool
}

function mapStateToProps({user}){
    return {loading:user.loading};

}

export default connect(mapStateToProps, {updateUserProfile})(LoanBusinessInfoForm);