import constant from "src/utils/constants/constants";
import { toast } from "react-toastify";
import {
  handleErrorMessage,
  setAccessToken,
} from "src/utils/helpers/functions";
import axios from "axios";

const errorAlert = (msg) => toast.error(msg);
// const successAlert = (msg) => toast.success(msg)

export const trainingRequest = (data, redirectUrl) => async (dispatch) => {
  dispatch({ type: "TRAINING_REQUEST" });
  setAccessToken();
  await axios
    .post(`${constant.BASE_API}/client/training/request`, data)
    .then(function (response) {
      dispatch({
        type: "TRAINING_REQUEST_SUCCESS",
        payload: response?.data,
        success: true,
      });
    })
    .then(function () {
      if (redirectUrl) {
        window.location.replace(redirectUrl);
      } else {
        return;
      }
    })
    .catch(function (error) {
      var errorMessage =
        error?.response?.data[0] || handleErrorMessage(error?.response);
      errorAlert(errorMessage);
      dispatch({ type: "TRAINING_REQUEST_FAILED", error: errorMessage });
    });
};

export const updateTrainingRequest =
  (data, redirectUrl) => async (dispatch) => {
    dispatch({ type: "TRAINING_REQUEST" });
    setAccessToken();
    const id = data?.id;
    await axios
      .patch(`${constant.BASE_API}/client/training/request/${id}`, data)
      .then(function (response) {
        dispatch({
          type: "UPDATE_TRAINING_REQUEST_SUCCESS",
          payload: response?.data,
          success: true,
        });
      })
      .then(function () {
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          return;
        }
      })
      .catch(function (error) {
        var errorMessage =
          error?.response?.data[0] || handleErrorMessage(error?.response);
        errorAlert(errorMessage);
        dispatch({
          type: "UPDATE_TRAINING_REQUEST_FAILED",
          error: errorMessage,
        });
      });
  };

export const userTrainingRequest = (data) => async (dispatch) => {
  dispatch({ type: "TRAINING_REQUEST" });
  setAccessToken();
  await axios
    .get(`${constant.BASE_API}/client/training/request`, data)
    .then(function (response) {
      dispatch({
        type: "USER_TRAINING_REQUEST_SUCCESS",
        payload: response?.data,
        success: true,
      });
    })
    .catch(function (error) {
      var errorMessage =
        error?.response?.data[0] || handleErrorMessage(error?.response);
      errorAlert(errorMessage);
      dispatch({ type: "USER_TRAINING_REQUEST_FAILED", error: errorMessage });
    });
};

export function updateUserTrainingData(data) {
  const { id: userId, data: requestData } = data;

  return axios.patch(
    `${constant.BASE_API}/client/training/request/${userId}`,
    requestData
  );
}

export function createTrainingData(data) {
  const { data: requestData } = data;

  return axios.post(
    `${constant.BASE_API}/client/training/request`,
    requestData
  );
}
