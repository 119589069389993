import React, { Fragment } from "react";
import { CloseButton, Col, Modal, Row } from "react-bootstrap";
import propTypes from 'prop-types'
import { TextField } from "@components/forms";
import MainButton from "@components/buttons/Buttons";

export default function ModalDiv({show, onHide, title, subTitle, body, actionButton}) {
   

    return (
        <Fragment>
            <Modal show={show} onHide={onHide} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static"
                keyboard={false} centered>
                <Modal.Title id="contained-modal-title-vcenter" className="py-2 px-4 mt-3">
                    <CloseButton className="float-end fs-6 small" onClick={onHide}/>
                    <TextField text={title}/>
                    
                </Modal.Title>
                <Modal.Body className="px-4">
                    {subTitle}
                    
                    {body}

                </Modal.Body>
                <Row className="py-4 px-4">
                    <Col  md={{ span: 6, offset: 6 }}>
                        <Row>
                            <Col>
                                <MainButton text={'Cancel'} variant="outline-primary" onClick={onHide}/>
                            </Col>
                            {actionButton &&
                                <Col>{actionButton}</Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    );
  }

  ModalDiv.propTypes = {
    show:propTypes.bool,
    onHide:propTypes.func,
    title:propTypes.string,
    subTitle:propTypes.string,
    body:propTypes.node,
    actionButton:propTypes.node
  }



  