import React, { Fragment } from "react";
import propTypes from "prop-types";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";

const FormInput = ({
  title,
  name,
  isRequired,
  isDisabled,
  type,
  placeholder,
  onChange,
  defaultValue,
  error,
  maxDate,
  accept,
  minDate,
}) => {
  return (
    <Fragment>
      {title && (
        <Form.Label>
          {title}{" "}
          {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        </Form.Label>
      )}
      <Form.Control
        name={name}
        required={isRequired}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
        defaultValue={defaultValue}
        max={maxDate}
        accept={accept}
        minDate={minDate}
        className={`${error && "is-invalid"}`}
      />
      <div className="invalid-feedback">{error}</div>
    </Fragment>
  );
};
FormInput.propTypes = {
  title: propTypes.string,
  name: propTypes.string,
  isRequired: propTypes.bool,
  isDisabled: propTypes.bool,
  type: propTypes.string,
  placeholder: propTypes.string,
  onChange: propTypes.func,
  defaultValue: propTypes.string,
  error: propTypes.any,
  maxDate: propTypes.string,
  accept: propTypes.string,
  minDate:propTypes.string
};

export const PhoneNumberInput = ({
  title,
  isRequired,
  placeholder,
  value,
  onChange,
  error,
}) => {
  return (
    <Fragment>
      {title && (
        <Form.Label>
          {title}{" "}
          {isRequired && <b className="text-danger fs-6 fw-bold">*</b>}
        </Form.Label>
      )}
      <PhoneInput
        maxLength={12}
        placeholder={placeholder}
        className={`${error && "is-invalid"} `}
        value={value}
        defaultCountry="GH"
        international={false}
        countryCallingCodeEditable={false}
        onChange={onChange}
      />
      <div className="invalid-feedback">{error}</div>
    </Fragment>
  );
};
PhoneNumberInput.propTypes = {
  title: propTypes.string,
  isRequired: propTypes.bool,
  placeholder: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  error: propTypes.string,
};

export default FormInput;
