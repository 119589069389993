import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ApplicationStatusCard from "./ApplicationStatusCard";
import BlogPostCard from "./BlogPostCard";
import LoanIncompleteCard from "./LoanIncompleteCard";
// import LoanPendingCard from "./LoanPendingCard";
import SeeMoreTitle from "./SeeMoreTitle";
import TrainingPreviewCard from "./TrainingPreviewCard";
import TrainingProgramCard from "./TrainingProgramCard";
import { useQuery } from "react-query";
import { getUserProfile } from "src/api/User/User";
import MainButton from "@components/buttons/Buttons";
import training_thumb from "@images/bg/jas_hero_5.jpg";
import training_thumb_1 from "@images/bg/jas_hero_6.jpg";

export default function Home() {
  const user = JSON.parse(window.localStorage.getItem("user"));
  const [redirectUrl, setRedirectUrl] = useState(false);

  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data", getUserProfile);

  const data = responseData?.data;

  const is_application_complete = data?.profile?.is_application_complete;

  function assignRedirect(data) {
    if (data && data?.onboarding_data) {
      const loan = data.onboarding_data;

      const allStages = [
        "personal_information",
        "education",
        "training",
        "accumen",
        "financial_literacy",
        "other_information",
        "application_terms",
        "completed",
      ];

      for (let stage of allStages) {
        const stageCompleted = loan[stage];

        if (!stageCompleted) {
          setRedirectUrl(`/onboarding/${stage}`);

          break;
        }
      }
    } else {
      setRedirectUrl(`/onboarding/personal_information`);
    }
  }

  useEffect(() => assignRedirect(data), [assignRedirect]);

  if (isLoading)
    return (
      <Container className="p-4">
        <h2 className="mt-4 me-4">Loading...</h2>;
      </Container>
    );

  if (error) return "An error has occurred: " + error.message;

  return (
    <section>
      <Row className="mx-4 my-4">
        <h2>Welcome, {user?.first_name || user?.user?.first_name}</h2>
      </Row>
      <Row className="mx-4 mt-2">
        <Col xs={12} xl={8}>
          <div className="">
            <h6>Application status</h6>
            <ApplicationStatusCard data={data} />
          </div>
          <div className="mt-5">
            {!is_application_complete ? (
              <LoanIncompleteCard>
                <MainButton
                  text="Continue Application"
                  dark={true}
                  link={redirectUrl || null}
                />
              </LoanIncompleteCard>
            ) : null}
          </div>
          <div className="mt-5">
            <h6>Training Programs</h6>
            <Row>
              <Col xs={12} md={6}>
                <TrainingProgramCard
                  title="Financial Accounting 101"
                  training_thumbnail="https://images.unsplash.com/photo-1508243529287-e21914733111?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                />
              </Col>
              <Col xs={12} md={6}>
                <TrainingProgramCard
                  title="Business Accounting for beginners"
                  training_thumbnail={training_thumb}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <TrainingProgramCard
                  title="Accounting for Agri-Business"
                  training_thumbnail={training_thumb_1}
                />
              </Col>
              <Col xs={12} md={6}>
                <TrainingProgramCard title="Inventory" />
              </Col>
            </Row>
          </div>
        </Col>

        <Col className="d-flex justify-content-start flex-column ms-4 mt-lg-4 mt-xl-none">
          <Row>
            <Col xs={12} md={6} xl={12}>
              <SeeMoreTitle title="Blog Posts" />
              <BlogPostCard />
            </Col>
            <Col xs={12} md={6} xl={12}>
              <SeeMoreTitle title="Announcements" />
              <TrainingPreviewCard />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}
