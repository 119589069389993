import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

import OnboardProgress from "@components/layout/navigation/OnboardProgressNav";
import { ToastContainer } from "react-toastify";
import { useQuery } from "react-query";
import { getUserProfile, updateFullUserProfile } from "src/api/User/User";
import { useMutation } from "react-query";
import { updateUserTrainingData } from "src/api/Training/Training";
import { useNavigate } from "react-router-dom";
import FinancialLiteracy from "@components/forms/Auth/Forms/FinancialLiteracy";
import { handleErrorMessage, messageAlert } from "@utils/helpers/functions";
import OnboardTopNav from "@components/layout/OnboardTopNav";

const FinancialLiteracyPage = () => {
  let navigate = useNavigate();

  const mutation = useMutation(updateUserTrainingData);
  const profile_mutation = useMutation(updateFullUserProfile);


  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery("user-data-verifcation", getUserProfile);

  // const state = responseData?.data?.profile;
  const trainingData = responseData?.data?.training_requests;
  const training_data = trainingData
    ? trainingData[trainingData?.length - 1]
    : {};

  const onboardingState = responseData?.data?.onboarding_data;
  const userId = responseData?.data?.user?.id;

  var onboarding_data = {...onboardingState, 'financial_literacy':true}

  if (isLoading) return <h1>Loading...</h1>;

  if (error) return <h1>Error...</h1>;

  const submitData = (data) => {
    let prevExp = training_data?.gift_expenditure?.split('^')
    data.gift_expenditure = `${data?.gift_expenditure1 || prevExp[0]}^${data?.gift_expenditure2 || prevExp[1]}^${data?.gift_expenditure3 || prevExp[2]}`
    mutation.mutate(
      { id: training_data.id, data },
      {
        onSuccess: () => {
          navigate("/onboarding/other_information");
        },
        onError: (error) => {
          var errorMessage = handleErrorMessage(error?.response);
          messageAlert("error", errorMessage);
        },
      }
    );
    profile_mutation.mutate(
      { id:userId, onboarding_data:onboarding_data }
    );
  };

  const navigateToPreviousPage = () => {
    navigate("/onboarding/accumen");
  };
  return (
    <Fragment>
      <ToastContainer />
      <OnboardTopNav/>
      <Row>
      <OnboardProgress page={5} />
      <Col md={9} sm={12}>
        
        <FinancialLiteracy
          state={training_data}
          onSubmit={submitData}
          loading={mutation.isLoading}
          onPreviousButtonClicked={navigateToPreviousPage}
        />
          
      </Col>
      </Row>
    </Fragment>
  );
};

export default FinancialLiteracyPage;
