import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import hero_imgae from "@images/bg/jas_hero_3.jpg";
import HeroButton from "@components/buttons/HeroButton";

export default function Hero() {
  var sectionStyle = {
    backgroundImage: `linear-gradient(rgba(12, 12, 12, 0.397), rgba(12, 12, 12, 0.479)), url(${hero_imgae})`,
    backgroundPosition: "50% 0%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "750px",
    color: "white",
    marginTop: "-50px",
  };

  return (
    <Container
      fluid
      style={sectionStyle}
      className="d-flex  align-items-middle justify-content-center "
    >
      <Container className="my-auto">
        <Row className="d-flex justify-content-center">
          <Col className="d-flex justify-content-center text-center ">
            <div className="" style={{ marginTop: "120px" }}>
              <h1
                className="mt-4 lh-xs  h1 fw-bold "
                style={{ color: "white" }}
              >
                Turn Your Business Idea Into <br /> A Reality
              </h1>
              <div className="d-md-flex mt-5 justify-content-center">
                <HeroButton label="Apply Now" link="/get_started" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
