import Education from '@components/forms/Auth/Forms/Education';
import PersonalInformation from '@components/forms/Auth/Forms/PersonalInformation';
// import Logo from '@components/utils';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { getUserProfile } from 'src/api/User/User';
import Training from '@components/forms/Auth/Forms/Training';
import BusniessAcumen from '@components/forms/Auth/Forms/BusinessAccumen';
import FinancialLiteracy from '@components/forms/Auth/Forms/FinancialLiteracy';
import OtherInformationVerification from '@components/forms/Auth/Forms/OtherInformationVerification';
import html2pdf from 'html2pdf.js';
import MainButton from '@components/buttons/Buttons';



export default function ApplicationPDF(){
    const ref = React.createRef();
    const {
        error,
        data: responseData,
        isLoading,
      } = useQuery("user-data-verifcation", getUserProfile);
    
      const state = responseData?.data?.profile;
      const user = responseData?.data?.user;

      const trainingData = responseData?.data?.training_requests;

        const training_data = trainingData
            ? trainingData[trainingData.length - 1]
            : {};

        var owns_a_business = responseData?.data?.profile?.owns_a_business;

        const stateTrainingData = {
          ...training_data,
          owns_a_business: owns_a_business
        };
      if (isLoading) return <h1>Loading...</h1>;
    
      const disabled = true;
      if (error)
        return (
          <div>
            <h1>there was an error</h1>
          </div>
        );

        

        const generatePdf = () =>{
            var element = document.getElementById('print-page');
            var opt = {
            margin:       1,
            filename:     `${user.first_name}_jas_application.pdf`,
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 1 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
            
            html2pdf().from(element).set(opt).save();
            
          }

        

    return(
        <Fragment>
      
            <MainButton onClick={generatePdf} text="Download PDF" variant="outline-primary" className='mb-5 mt-3 col-md-2'/>
            <div ref={ref} id="print-page">
                <Row >
                    <Col className="d-flex justify-content-center">
                    
                    <Col>
                        <Row className='mb-5'>
                            <Col className="d-flex justify-content-center fw-bold text-center">
                                GHANA ENTERPRISES AGENCY (GEA) <br/>
                                GHANA JOBS AND SKILLS PROJECT (GJSP) COMPONENT 2.1 <br/>
                                APPLICATION FORM
                            </Col>
                            
                        </Row>
                        <Row className='mb-5'>
                            <Col >
                                   
                                NAME OF APPLICANT: <b className='fw-bold'>{user?.first_name} {user?.last_name}</b><br/>
                                GEA NUMBER OF THE APPLICANT: <b className='fw-bold'>{user?.id}</b><br/>
                                DATE OF APPLICATION: <b className='fw-bold'>{state?.created?.split('T')[0]}</b>
                                   
                            </Col>
                        </Row>
                        <Row >
                            <Col className="d-flex justify-content-center">
                                <PersonalInformation disabled={disabled} state={state} colSize={12}/>
                            </Col>
                        </Row>
                        <div className="html2pdf__page-break"/>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Education disabled={disabled} state={state} colSize={12}/>
                            </Col>
                        </Row>
                        <div className="html2pdf__page-break"/>
                        <Row >
                            <Col className="d-flex justify-content-center">
                                <Training disabled={disabled} state={stateTrainingData} colSize={12}/>
                            </Col>
                        </Row>
                        <div className="html2pdf__page-break"/>
                        <Row >
                            <Col className="d-flex justify-content-center">
                                <BusniessAcumen disabled={disabled} state={stateTrainingData} colSize={12}/>
                            </Col>
                        </Row>
                        <div className="html2pdf__page-break"></div>
                        <Row >
                            <Col className="d-flex justify-content-center">
                                <FinancialLiteracy disabled={disabled} state={stateTrainingData} colSize={12}/>
                            </Col>
                        </Row>
                        <div className="html2pdf__page-break"></div>
                        <Row >
                            <Col className="d-flex justify-content-center">
                                <OtherInformationVerification disabled={disabled} state={state} colSize={12}/>
                            </Col>
                        </Row>
                    </Col>
                    </Col>
                </Row>
            </div>
        </Fragment>

    )

}
  