import React, { Fragment, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import propTypes from "prop-types";
import Login from "@views/Login/LoginPage";
import GetStarted from "@views/Login/GetStartedPage";
import Signup from "@views/Signup/SignUpPage";
import "./App.css";
import StaticPagesBaseLayout from "@components/layout/layouts/StaticPagesBaseLayout/StaticPagesBaseLayout";
import { HomePage } from "./views";
import TermsAndConditions from "@views/Terms/TermsAndConditions";
import FAQ from "@views/Faqs/FAQ";
import SignUpSuccess from "@views/Signup/SignUpSuccessPage";
import DashboardBaseLayout from "@components/layout/layouts/DashboardBaseLayout.js/DashboardBaseLayout";
import Home from "@views/Dashboard/Home/Home";
import { ToastContainer } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import PersonalInfo from "@views/Signup/Onboarding/PersonalInfoPage";
import LoanHome from "@views/Dashboard/Loan/Home";
import BusinessInfo from "@views/Dashboard/Loan/LoanApplication/BusinessInfo";
import BusinessLocation from "@views/Dashboard/Loan/LoanApplication/BusinessLocation";
import BusinessFinancialInfo from "@views/Dashboard/Loan/LoanApplication/BusinessFinancialInfo";
import BusinessSupportHistoryInfo from "@views/Dashboard/Loan/LoanApplication/BusinessSupportHistory";
import BusinessFirstGuarantor from "@views/Dashboard/Loan/LoanApplication/BusinessGuarantorInfo1";
import BusinessSecondGuarantor from "@views/Dashboard/Loan/LoanApplication/BusinessGuarantorInfo2";
import BusinessDeclaration from "@views/Dashboard/Loan/LoanApplication/BusinessDeclaration";
import LoanSuccess from "@views/Dashboard/Loan/LoanApplication/LoanSuccess";
import { refreshToken } from "./utils/helpers/functions";
import LoanApplicationBaseLayout from "@components/layout/layouts/LoanApplicationLayout/LoanApplicationBaseLayout";
import { QueryClient, QueryClientProvider } from "react-query";
import OnboardLayout from "@components/layout/OnboardLayout";
import EducationalInfo from "@views/Signup/Onboarding/EducationInfoPage";
import TrainingInfo from "@views/Signup/Onboarding/TraningInfoPage";
import BusniessAcumen from "@views/Signup/Onboarding/BusinessAcumenPage";
import OtherInfo from "@views/Signup/Onboarding/OtherInfoPage";
import ApplicationTerms from "@views/Signup/Onboarding/ApplicationTermsPage";
import LoanPayment from "@views/Dashboard/LoanPayment/LoanPayment";
import Verification from "@views/Signup/Onboarding/Verification";
import SuccessPage from "@components/forms/Auth/SuccessPage";
import ApplicationPDF from "@views/Signup/Onboarding/ApplicationPDFPage";
import FinancialLiteracyPage from "@views/Signup/Onboarding/FinancialLiteracyPage";
import Training from "@views/Dashboard/Training/Training";
import About from "@views/About/About";
import AboutGea from "@views/AboutGea/AboutGea";
import UserProfile from "@views/Dashboard/Profile/Profile";

function App() {
  function RequireAuth({ children }) {
    const [refresh, setRefresh] = useState(1);
    useEffect(() => {
      refreshToken();
    }, [refresh]);
    setTimeout(function () {
      setRefresh(refresh + 1);
    }, 240000);

    let location = useLocation();
    const user = JSON.parse(window.localStorage.getItem("user"));

    if (!user) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
  }

  RequireAuth.propTypes = {
    children: propTypes.node,
  };

  const queryClient = new QueryClient();

  return (
    <Fragment>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/get_started" element={<GetStarted />} />
            <Route path="/signup" element={<Signup />} />

            <Route
              path="/signup/personal_info"
              element={
                <RequireAuth>
                  <PersonalInfo />
                </RequireAuth>
              }
            />
            <Route path="/signup/success" element={<SignUpSuccess />} />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <DashboardBaseLayout />
                </RequireAuth>
              }
            >
              <Route index element={<Home />} />
              <Route
                path="/dashboard/grant_payment"
                element={<LoanPayment />}
              />
              <Route path="/dashboard/training" element={<Training />} />
              <Route path="/dashboard/profile" element={<UserProfile />} />
            </Route>
            <Route
              path="/loan_application"
              element={
                <RequireAuth>
                  <LoanApplicationBaseLayout />
                </RequireAuth>
              }
            >
              <Route index element={<LoanHome />} />
              <Route
                path="/loan_application/business_info"
                element={<BusinessInfo />}
              />
              <Route
                path="/loan_application/business_location"
                element={<BusinessLocation />}
              />
              <Route
                path="/loan_application/business_financial_info"
                element={<BusinessFinancialInfo />}
              />
              <Route
                path="/loan_application/business_support_history"
                element={<BusinessSupportHistoryInfo />}
              />
              <Route
                path="/loan_application/business_guarantor_1"
                element={<BusinessFirstGuarantor />}
              />
              <Route
                path="/loan_application/business_guarantor_2"
                element={<BusinessSecondGuarantor />}
              />
              <Route
                path="/loan_application/business_declaration"
                element={<BusinessDeclaration />}
              />
              <Route
                path="/loan_application/success"
                element={<LoanSuccess />}
              />
            </Route>
            <Route
              path="/onboarding"
              element={
                <RequireAuth>
                  <OnboardLayout />
                </RequireAuth>
              }
            >
              <Route index element={<PersonalInfo />} />
              <Route
                path="/onboarding/personal_information"
                element={<PersonalInfo />}
              />
              <Route
                path="/onboarding/education"
                element={<EducationalInfo />}
              />
              <Route path="/onboarding/training" element={<TrainingInfo />} />
              <Route path="/onboarding/accumen" element={<BusniessAcumen />} />
              <Route
                path="/onboarding/financial_literacy"
                element={<FinancialLiteracyPage />}
              />
              <Route
                path="/onboarding/other_information"
                element={<OtherInfo />}
              />
              <Route
                path="/onboarding/verification"
                element={<Verification />}
              />
              <Route
                path="/onboarding/application_terms"
                element={<ApplicationTerms />}
              />
              <Route path="/onboarding/success" element={<SuccessPage />} />
              <Route
                path="/onboarding/application_pdf"
                element={<ApplicationPDF />}
              />
            </Route>
            <Route path="/" element={<StaticPagesBaseLayout />}>
              <Route index element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/faqs" element={<FAQ />} />
              <Route path="/about" element={<About />} />
              <Route path="/about-gea" element={<AboutGea />} />
            </Route>
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>Sorry, there is nothing here</p>
                </main>
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Fragment>
  );
}

export default App;
