import React, {Fragment, useState} from "react";
import MainButton from "@components/buttons/Buttons";
import ModalDiv from "@components/modals/Modal";
import { Form, Row, Col} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import './style.css'
import { TextField } from "..";
import { PhoneNumberInput } from "../FormInput/FormInput";
import {getOtp, optLogin} from "src/api/Auth/Auth";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import { handleErrorMessage, handleLoginRedirect, messageAlert, storeLocalStorage } from "@utils/helpers/functions";
import OTPLogin from "./OtpLogin";


const validationSchema = yup.object({
    phone_number: yup.string().required("Required")
  });



const LoginForm = () =>{
    const navigate = useNavigate()

    const {
        control,
        getValues,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(validationSchema),

    });

    const [otpModal, setOtpModal] = useState(false)
    const [otp, setOtp] = useState('')
    const handleOtpChange = (otp) => setOtp(otp);
    const subTitle = 'Enter the GEA login pin that was sent to your SMS to proceed to your account dashboard.';
    
    const mutation = useMutation(optLogin);
    const mutation_get_otp = useMutation(getOtp);
    const handleLogin = () => {
            var data = {code:otp, ...getValues()}
            mutation.mutate(data, {
            onSuccess: (response) => {
                storeLocalStorage('user', JSON.stringify(response?.data))
                storeLocalStorage('accessToken', response.headers?.["set-auth-token"])

                if(response?.data?.profile?.is_application_complete){
                    navigate('/dashboard')
                }else{
                    navigate(handleLoginRedirect(response?.data?.onboarding_data))
                }
            },
            onError: (error) => {
               
                var erroMesaage = handleErrorMessage(error?.response)
                messageAlert('error', erroMesaage)
            }
        })
    }

    const handleGetOtp = (data) => 
    {   
        setOtpModal(true);
        data.verification_type = 'phone_number';
        data.verification_action = 'login';
        mutation_get_otp.mutate(data, {
            onSuccess: () => {
                setOtpModal(true);
            },
            onError: (error) => {
                console.log(error?.response,'error goes here')

                if (error?.response?.status == 400)
                {
                    
                    messageAlert('error',error?.response?.data[0]);
                    return;

                }
                var erroMesaage = handleErrorMessage(error?.response)
                messageAlert('error', erroMesaage)
            }
        })
        
        
    }

   
    return(
        <div className="d-flex justify-content-center align-items-center h-100">
            <Form className="mt-2 col-md-8" onSubmit={handleSubmit(handleGetOtp)}>
                <TextField text={'Login to your account'} className={'fs-4 fw-bold mb-3'}/>
                <TextField text={'Enter your phone number and unique code to access your account'} className={'mb-5'}/>

                <Form.Group as={Col} className="mb-5">
                    <Controller
                        name="phone_number"
                        control={control}
                        render={({ field }) => <PhoneNumberInput
                        {...field}
                        title="Phone number" 
                        placeholder="Phone number" 
                        isRequired={true}
                        error={errors?.phone_number?.message}
                        />}
                    />
                </Form.Group>
                
                <div className="d-grid gap-2 mb-5">
                    <MainButton variant="primary" size="md"
                        type="submit"
                        isLoading={mutation_get_otp.isLoading} 
                        text='Login'
                        loadingVariant='light'
                        />
                </div>
                <Row>
                    <Col>
                        <TextField 
                            text={<Fragment>New here?<Link to="/get_started" className="text-decoration-none mx-1"> Start application</Link></Fragment>} 
                            className="py-5 px-5 text-center fw-bold mb-5"/>
                    </Col>
                </Row>
            </Form>
            <ModalDiv show={otpModal} 
                onHide={() => setOtpModal(false)} title='Enter Your GEA Login Pin'
                subTitle={subTitle}
                body={<OTPLogin otp={otp} handleChange={handleOtpChange} requestCode="Didn’t get a code? Contact support for a new code +233 30 701 0325" phoneNumber = {watch("phone_number")}/>}
                actionButton={<MainButton text={'Send'} onClick={()=>handleLogin()} isLoading={mutation.isLoading} isDisabled={otp?.length !== 6}/>}/>
        </div>
    )
}


export default LoginForm;

